// Score.js

import React from "react";
import { useParams } from "react-router-dom"; // Import useParams from react-router-dom
import Footer from "./Include/Footer1";
import Nav from "./Include/Nav";
import arrow1 from "./arrow.svg";
import { useLocation } from 'react-router-dom';

import { Helmet } from "react-helmet";

function Score() {
  const breadcumb = "https://dev-upkl.upkl.in/files/breadcumb1.png";

  // Use useParams hook to get matchid from URL
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const matchid = queryParams.get('matchid');

  return (
    <>
      <Nav />
     
      <section>
        {/* Dynamically set the src attribute of the iframe */}
        <iframe
          className="iframenew"
          src={`https://dz8zbd8amw7pd.cloudfront.net/UPKL/widgets/KabaddiMatchCenter/index.html?matchid=${matchid}`}
          width="100%"
          height="100%"
          frameBorder="0"
          scrolling="auto"
          title="Kabaddi Match Center"
          style={{ overflow: "hidden" }}
        />
      </section>
      <Footer />
    </>
  );
}

export default Score;
