import React, { useState, useEffect } from "react";
import Nav from "../Include/Nav";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Footer from "../Include/Footer1";
import { Link } from "react-router-dom";

import fb from "../fb.png";
import inst from "../instagram.png";
import you from "../you-tube.png";
import twitter from "../twitter.png";
import arrow from "../arrow.svg";
import New from "../Components/New";
import b1 from './UPKL1.jpg'
import b3 from './UPKL3.jpg'
import b4 from './UPKL4.jpg'
import b6 from './UPKL6.jpg'
import b7 from './UPKL7.jpg'
import b8 from './UPKL8.jpg'
import b9 from './UPKL9.jpg'
import b11 from './UPKL11.jpg'
import b12 from './UPKL12.jpg'

export default function SingleBlog2() {
  const [standings, setStandings] = useState([]);
  const [loading, setLoading] = useState(true);

 
  return (
    <>
      <Nav />

      <div className="singleblog">
        <div className="row1">
          <div className="blogbanner">
            <img
              src='https://dev-upkl.upkl.in//files/dghdgfgjh (1).jpg'
              className="img-fluid"
              alt="UPKL 2024"
            />
          </div>

          <div className="blog-details">
            <div className="col-md-8 col-12 offset-md-2">
              <div className="row">
                <div className="blogheadng">
                  <h2>
                    UPKL ANNOUNCES BINGO TEDHE MEDHE AS A PRINCIPAL SPONSOR FOR
                    THE ENDEAVOUR
                    <img src={arrow} className="arrow img-fluid" />
                  </h2>
                </div>

                <div className="blogline"></div>

                <div className="row row1">
                  <div className="col-md-6 col-6">
                    <p className="blogdate">18 July 2024</p>
                  </div>
                  <div className="col-3 mobilenone"></div>
                  <div className="col-md-3 col-6 d-flex">
                    <p className="blogshareicon">Social</p>
                    <div className="footerrightmenu">
                      <div className="bloglogo">
                        <ul className="d-flex pl-0">
                          <li className="">
                            <Link
                              target="_blank"
                              to='/blog-detail?name=UPKL%20announces%20BINGO%20TEDHE%20MEDHE%20as%20a%20Principal%20Sponsor%20for%C2%A0the%C2%A0endeavour'
                            >
                              {" "}
                              <img src={fb} />
                            </Link>
                          </li>
                          {/* <li>

                            <Link target="_blank" src={`http://www.linkedin.com/shareArticle?mini=true&url=${standings.title}`}>
                            <img src={inst} />
                            </Link>
                          </li> */}
                          {/* <li>
                            <img src={you} />
                          </li> */}
                          <Link
                            target="_blank"
                            to={`http://twitter.com/share?text=${standings.title}`}
                          >
                            <img src={twitter} />
                          </Link>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="blogpara p-0">
                  <div class="blogpara p-0">
                    <p>
                      <div class="ql-editor read-mode">
                        <p>
                          In a thrilling fusion of sportsmanship and flavor, the
                          newly launched Uttar Pradesh Kabaddi League (UPKL) and
                          Bingo Tedhe Medhe have teamed up to create an
                          unforgettable experience for fans across the nation.
                          This partnership marks a dynamic convergence of
                          athleticism and culinary innovation, promising to
                          redefine how fans enjoy their favorite sport and
                          snacks.
                        </p>
                        <p>
                          <strong>Uttar Pradesh Kabaddi League (UPKL):</strong>
                        </p>
                        <p>
                          Making its debut this season, UPKL is set to
                          revolutionize the regional sports scene in India with
                          electrifying matches and a strong commitment to
                          promoting kabaddi talent. As a fresh platform for
                          emerging athletes, UPKL aims to foster community
                          spirit and celebrate the essence of kabaddi—a sport
                          deeply rooted in Indian culture. With an ambitious
                          vision and a passion for excellence, UPKL is poised to
                          captivate audiences with its spirited gameplay and
                          inclusive approach.
                        </p>
                        <p>
                          <strong>Bingo Tedhe Medhe:</strong>
                        </p>
                        <p>
                          Meanwhile, Bingo Tedhe Medhe has captured the hearts
                          and taste buds of snack enthusiasts nationwide with
                          its bold flavors and innovative combinations. Renowned
                          for its quirky twists and adventurous taste profiles,
                          Bingo Tedhe Medhe has redefined snacking experiences,
                          offering a delightful blend of excitement and flavor
                          in every bite. It has become synonymous with fun and
                          adventure, reflecting a commitment to culinary
                          creativity and quality that resonates with diverse
                          audiences.
                        </p>

                        <div className="row">
                            <div className="col-12 col-md-4">
                                <img src={b1}  className="img-fluid"/>
                            </div>

                            <div className="col-12 col-md-4">
                                <img src={b3}  className="img-fluid"/>
                            </div>


                            <div className="col-12 col-md-4">
                                <img src={b4}  className="img-fluid"/>
                            </div>
                        </div>
                        <p>

                          <strong>A Collaboration of Innovation:</strong>
                        </p>
                        <p>
                          The collaboration between UPKL and Bingo Tedhe Medhe
                          represents more than just a partnership—it's a
                          celebration of synergy and shared values. Together,
                          they aim to enhance the fan experience through a range
                          of interactive initiatives, both in-stadium and
                          digitally. Fans can look forward to exciting contests,
                          exclusive merchandise, interactive fan zones, and
                          engaging digital content that combine the thrill of
                          kabaddi with the zest of Bingo Tedhe Medhe snacks.
                        </p>
                        <p>
                          <strong>Looking Ahead:</strong>
                        </p>
                        <p>
                          As they embark on this exhilarating journey together,
                          UPKL and Bingo Tedhe Medhe are committed to pushing
                          boundaries and setting new standards in sports
                          entertainment and snacking. This collaboration isn't
                          just about the present season; it's about building a
                          lasting legacy of innovation and excitement in the
                          sports industry. They plan to continue delighting fans
                          with ongoing collaborations that showcase the best of
                          kabaddi and Bingo Tedhe Medhe's irresistible snacks.
                        </p>


                        <div className="row">
                            <div className="col-12 col-md-4">
                                <img src={b8}  className="img-fluid"/>
                            </div>

                            <div className="col-12 col-md-4">
                                <img src={b6}  className="img-fluid"/>
                            </div>


                            <div className="col-12 col-md-4">
                                <img src={b7}  className="img-fluid"/>
                            </div>
                        </div>


                        <p>
                          <strong>Join Us:</strong>
                        </p>
                        <p>
                          Get ready to experience kabaddi like never before,
                          paired with the bold flavors and playful spirit of
                          Bingo Tedhe Medhe snacks. Follow UPKL and Bingo Tedhe
                          Medhe for updates, behind-the-scenes insights, and
                          exclusive content that celebrate the fusion of
                          sportsmanship and flavor. Together, let's celebrate
                          the power of collaboration and the joy of shared
                          experiences in sports and snacking!
                        </p>
                        <p>
                          Stay tuned as UPKL and Bingo Tedhe Medhe redefine the
                          game—and your snacking experience—with every thrilling
                          match and delicious bite. Let the
                          excitement&nbsp;begin!
                        </p>
                      </div>
                    </p>

                    <div className="row">
                            <div className="col-12 col-md-4">
                                <img src={b9}  className="img-fluid"/>
                            </div>

                            <div className="col-12 col-md-4">
                                <img src={b11}  className="img-fluid"/>
                            </div>


                            <div className="col-12 col-md-4">
                                <img src={b12}  className="img-fluid"/>
                            </div>
                        </div>


                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <New />

      <Footer />
    </>
  );
}
