import React from "react";
import Nav from "../Include/Nav";
import Footer from "../Include/Footer1";
import arrow from "../arrow.svg";
import { Helmet } from "react-helmet";
import Banner from './screen-03-with-line (1).jpg'
import msposnor from './mobile-cover-02.jpg'
export default function Sponsor() {
  const breadcumb = "https://dev-upkl.upkl.in/files/breadcumb1.png";

  return (
    <>
      <Helmet>
        <title>Uttar Pradesh Kabaddi League (UPKL) - Empowering Kabaddi Athletes.</title>
        <meta name="title" content="Uttar Pradesh Kabaddi League (UPKL) - Empowering Kabaddi Athletes." />
        <meta
          name="description"
          content="Learn about the Uttar Pradesh Kabaddi League (UPKL), a dedicated platform committed to nurturing local Kabaddi talents. Understand UPKL's mission to engage audiences and reinforce Kabaddi's significance in India."
        />
        <link rel="canonical" href="http://upkl.in/about" />
      </Helmet>

      <Nav />

      <section className="breadcrumb">

        <img src={breadcumb} className="img-fluid" alt="Breadcrumb Image" />
        <div className="breadcrumb-caption">
          <div className="d-flex">
            <div className="newarrows">
              <h2 className="text-white">SPONSORS</h2>
              <div className="linetilte"></div>
            </div>
            <div className="abc">
              <img src={arrow} className="textrightarrow" alt="Uttar Pradesh Kabaddi League (UPKL)" />
            </div>{" "}
          </div>
        </div>
      </section>

      <section className="aboutsection sponsors">
        <div className="container-fluid">
        <img src={Banner} className="img-fluid sponsorimgbanner dshow"/>
        <img src={msposnor} className="img-fluid sponsorimgbanner mshow"/>

<br></br>
          {/* Bingo Sponsor Section */}
          <div className="titlecaption">
            <div className="d-flex">
              <div className="newarrows">
                <h2 className="text-white">Bingo Tedhe Medhe
                : Energizing the Uttar Pradesh Kabaddi League</h2>
                <div className="linetilte"></div>
              </div>
              
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 col-12 align-self-center">
              <div className="aboutuspara">
                <p>
                Bingo Tedhe Medhe
                , a popular snack brand, is one of the proud sponsors of the Uttar Pradesh Kabaddi League (UPKL), which kicks off tomorrow. Known for its diverse range of chips and other savory snacks, Bingo has become a household name across India. Their sponsorship of the UPKL demonstrates their commitment to promoting sports and engaging with the local community.
                </p>
                <p>
                  Bingo's vibrant and youthful brand image aligns perfectly with the dynamic and energetic nature of kabaddi. Their support not only helps in organizing a successful league but also brings more visibility to the sport.
                </p>
              </div>
            </div>
          </div>




          <div className="titlecaption">
            <div className="d-flex">
              <div className="newarrows">
                <h2 className="text-white">Ghadi : Enriches Efforts in UPKL </h2>
                <div className="linetilte"></div>
              </div>
              
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 col-12 align-self-center">
              <div className="aboutuspara">
                <p>
                Ghadi, a prominent detergent brand, has partnered with the Uttar Pradesh Kabaddi League (UPKL) as a sponsor and co-powered partner. This collaboration provides UPKL with crucial financial support and enhanced brand visibility, helping to expand the league's reach and improve its organizational capacity. Ghadi's involvement enriches UPKL's efforts in promoting kabaddi and engaging communities across Uttar Pradesh.
                </p>
               
              </div>
            </div>
          </div>


              {/* 1X Bat Sponsor Section */}
              <div className="titlecaption">
            <div className="d-flex">
              <div className="newarrows">
                <h2 className="text-white">1X BAT : Supporting Excellence in UPKL</h2>
                <div className="linetilte"></div>
              </div>
              
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 col-12 align-self-center">
              <div className="aboutuspara">
                <p>
                  1X BAT, a leading sports equipment brand, sponsors the UPKL to support the growth of kabaddi. Their high-quality gear is trusted by athletes worldwide, and their involvement in the league highlights their commitment to excellence and innovation in sports.
                </p>
              </div>
            </div>
          </div>
          {/* End 1X Bat Sponsor Section */}




        {/* 1X Bat Sponsor Section */}
        <div className="titlecaption">
            <div className="d-flex">
              <div className="newarrows">
                <h2 className="text-white">1X Sportz : Uniting Athelete Excellence in UPKL</h2>
                <div className="linetilte"></div>
              </div>
              
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 col-12 align-self-center">
              <div className="aboutuspara">
                <p>
                The Uttar Pradesh Kabaddi League, partnered with 1X Sportz, is set to revolutionize local sports. This collaboration aims to showcase regional Kabaddi talent through competitive matches across the state. With 1X Sportz managing the event, expect high-energy games that unite communities and promote athletic excellence. This league not only celebrates Kabaddi's cultural significance but also fosters a vibrant sports culture in Uttar Pradesh, promising an exciting season ahead for players and fans alike.
                </p>
              </div>
            </div>
          </div>
          {/* End 1X Bat Sponsor Section */}




   {/* 1X Bat Sponsor Section */}
   <div className="titlecaption">
            <div className="d-flex">
              <div className="newarrows">
                <h2 className="text-white">C-Hawk : Building the Strength of UPKL</h2>
                <div className="linetilte"></div>
              </div>
              
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 col-12 align-self-center">
              <div className="aboutuspara">
                <p>
                C-Hawk, a prominent cement company, has joined the Uttar Pradesh Kabaddi League (UPKL) as its official strength partner. This partnership enhances UPKL's training facilities and infrastructure, benefiting athletes and boosting the league's competitive edge. C-Hawk's support underscores its commitment to sports development in Uttar Pradesh, strengthening UPKL's position as a leading kabaddi platform in the region.                </p>
              </div>
            </div>
          </div>
          {/* End 1X Bat Sponsor Section */}


  {/* Fun 88 Sponsor Section */}
  <div className="titlecaption">
            <div className="d-flex">
              <div className="newarrows">
                <h2 className="text-white">Fun88 : Adding Thrill to UPKL</h2>
                <div className="linetilte"></div>
              </div>
              
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 col-12 align-self-center">
              <div className="aboutuspara">
                <p>
                  Fun88, an online gaming and entertainment platform, sponsors the UPKL to add an extra layer of excitement for fans. Their interactive features and engaging content will enhance the viewing experience, making the league more thrilling for everyone involved.
                </p>
              </div>
            </div>
          </div>
          {/* End Fun 88 Sponsor Section */}



            {/* Fun 88 Sponsor Section */}
  <div className="titlecaption">
            <div className="d-flex">
              <div className="newarrows">
                <h2 className="text-white">Sony Sports Network : Showcasing The Talent & Competitiveness of UPKL </h2>
                <div className="linetilte"></div>
              </div>
              
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 col-12 align-self-center">
              <div className="aboutuspara">
                <p>
                Sony Sports Network has partnered with the Uttar Pradesh Kabaddi League (UPKL) as its broadcasting partner, a move that significantly enhances UPKL's visibility and reach. This collaboration ensures that UPKL matches and events are broadcasted widely across Sony Sports Network's platforms, including television and digital channels. As a result, kabaddi enthusiasts across Uttar Pradesh and beyond can enjoy live coverage and updates, thereby increasing the league's fan base and engagement levels. Sony Sports Network's expertise in sports broadcasting also elevates UPKL's profile, showcasing the talent and competitiveness of local kabaddi players to a national audience. This partnership not only promotes kabaddi as a mainstream sport but also solidifies UPKL's position as a premier sporting event in Uttar Pradesh.                </p>
              </div>
            </div>
          </div>
          {/* End Fun 88 Sponsor Section */}




          {/* Fancode Sponsor Section */}
          <div className="titlecaption">
            <div className="d-flex">
              <div className="newarrows">
                <h2 className="text-white">Fancode: Bringing Live Action to UPKL Fans</h2>
                <div className="linetilte"></div>
              </div>
              
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 col-12 align-self-center">
              <div className="aboutuspara">
                <p>
                  Fancode, a premier sports streaming service, is ensuring that kabaddi fans don't miss a moment of the UPKL action. As a sponsor, Fancode will provide live coverage, exclusive content, and in-depth analysis, making the league accessible to viewers everywhere.
                </p>
              </div>
            </div>
          </div>
          {/* End Fancode Sponsor Section */}







          {/* End Bingo Sponsor Section */}

          {/* Hustle Sponsor Section */}
          <div className="titlecaption">
            <div className="d-flex">
              <div className="newarrows">
                <h2 className="text-white">Hustle : Fueling the Spirit of UPKL</h2>
                <div className="linetilte"></div>
              </div>
              
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 col-12 align-self-center">
              <div className="aboutuspara">
                <p>
                  Hustle, a brand synonymous with energy and determination, is sponsoring the Uttar Pradesh Kabaddi League (UPKL) starting tomorrow. Known for their athletic wear and accessories, Hustle’s support underscores their dedication to empowering athletes and promoting active lifestyles. Their sponsorship brings a fresh wave of enthusiasm to the league, inspiring players to give their best on the mat.
                </p>
              </div>
            </div>
          </div>
          {/* End Hustle Sponsor Section */}






             {/* Hustle Sponsor Section */}
             <div className="titlecaption">
            <div className="d-flex">
              <div className="newarrows">
                <h2 className="text-white">CLEAR : Refreshing the Experience of UPKL</h2>
                <div className="linetilte"></div>
              </div>
              
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 col-12 align-self-center">
              <div className="aboutuspara">
                <p>
                CLEAR, a leading water brand renowned for its purity and hydration benefits, partners with the Uttar Pradesh Kabaddi League (UPKL) as its official hydration sponsor. This partnership ensures that athletes have access to clean, refreshing water throughout the league, supporting their performance and well-being during matches. Clear's commitment to hydration enhances the overall experience of UPKL, promoting health and enabling players to perform at their peak.                </p>
              </div>
            </div>
          </div>
          {/* End Hustle Sponsor Section */}


                   
          {/* Red FM Sponsor Section */}
          <div className="titlecaption">
            <div className="d-flex">
              <div className="newarrows">
                <h2 className="text-white">Uttar Pradesh Tourism : Boosting the Essence of UPKL</h2>
                <div className="linetilte"></div>
              </div>
              
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 col-12 align-self-center">
              <div className="aboutuspara">
                <p>
                Uttar Pradesh Tourism partnering with the Uttar Pradesh Kabaddi League (UPKL) could significantly boost the league's visibility and appeal. As a tourism partner, Uttar Pradesh Tourism can leverage UPKL's platform to promote the cultural and tourist attractions of the state. This collaboration could involve promoting kabaddi events as tourist attractions, showcasing local cuisine, arts, and crafts during matches, and organizing tours to nearby heritage sites for visiting teams and fans. Such initiatives not only enhance the overall experience for spectators but also contribute to the economic growth of the region by attracting more visitors and boosting local businesses.                </p>
              </div>
            </div>
          </div>
          {/* End Red FM Sponsor Section */}

       

          {/* Red FM Sponsor Section */}
          <div className="titlecaption">
            <div className="d-flex">
              <div className="newarrows">
                <h2 className="text-white">Red FM 93.5 : Amplifying Excitement in UPKL</h2>
                <div className="linetilte"></div>
              </div>
              
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 col-12 align-self-center">
              <div className="aboutuspara">
                <p>
                Red FM 93.5, one of Uttar Pradesh's most popular radio stations, is bringing its vibrant energy to the UPKL as a sponsor. With its wide reach and engaging content, Red FM will be instrumental in creating a buzz around the league, keeping fans informed and entertained with live updates, interviews, and match highlights.





</p>
              </div>
            </div>
          </div>
          {/* End Red FM Sponsor Section */}

   {/* Bridge Sponsor Section */}
   <div className="titlecaption">
            <div className="d-flex">
              <div className="newarrows">
                <h2 className="text-white">The Bridge: Connecting Communities Through UPKL</h2>
                <div className="linetilte"></div>
              </div>
              
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 col-12 align-self-center">
              <div className="aboutuspara">
                <p>
                  The Bridge, a renowned digital platform, joins the UPKL as a sponsor to promote community engagement and sportsmanship. By leveraging their extensive network, Bridge aims to connect kabaddi fans across the region, fostering a sense of unity and excitement around the league.
                </p>
              </div>
            </div>
          </div>
          {/* End Bridge Sponsor Section */}




          
          {/* Khel Now Kabaddi Sponsor Section */}
          <div className="titlecaption">
            <div className="d-flex">
              <div className="newarrows">
                <h2 className="text-white">KhelNow Kabaddi: Amplifying Kabaddi’s Reach</h2>
                <div className="linetilte"></div>
              </div>
              
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 col-12 align-self-center">
              <div className="aboutuspara">
                <p>
                KhelNow Kabaddi, a popular sports news platform, is the outreach partner with the UPKL to boost the sport’s visibility. Their extensive coverage will provide fans with the latest updates, insights, and stories, making the league more engaging and informative.





</p>
              </div>
            </div>
          </div>
          {/* End Khel Now Kabaddi Sponsor Section */}




          {/* Motherland Hospital Sponsor Section */}
          <div className="titlecaption">
            <div className="d-flex">
              <div className="newarrows">
                <h2 className="text-white">Motherland Hospital: Ensuring Health and Safety in UPKL</h2>
                <div className="linetilte"></div>
              </div>
              
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 col-12 align-self-center">
              <div className="aboutuspara">
                <p>
                  Motherland Hospital, a top healthcare provider, is sponsoring the UPKL to ensure the health and safety of all participants. Their medical expertise and facilities will be on standby throughout the league, providing essential support and care.
                </p>
              </div>
            </div>
          </div>
          {/* End Motherland Hospital Sponsor Section */}



   {/* Kabaddi360 Sponsor Section */}
   <div className="titlecaption">
            <div className="d-flex">
              <div className="newarrows">
                <h2 className="text-white">Kabaddi360: Comprehensive Coverage for UPKL</h2>
                <div className="linetilte"></div>
              </div>
              
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 col-12 align-self-center">
              <div className="aboutuspara">
                <p>
                Kabaddi360, a dedicated kabaddi platform, is the outreach partner for the UPKL, offering comprehensive coverage of the league. Their in-depth analyses, player interviews, and match reviews will keep fans thoroughly engaged and informed throughout the season.





</p>
              </div>
            </div>
          </div>
          {/* End Kabaddi360 Sponsor Section */}

 
        

          {/* M Power Sponsor Section */}
          <div className="titlecaption">
            <div className="d-flex">
              <div className="newarrows">
                <h2 className="text-white">MPower: Empowering Athletes in UPKL</h2>
                <div className="linetilte"></div>
              </div>
              
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 col-12 align-self-center">
              <div className="aboutuspara">
                <p>
                MPower, a brand dedicated to empowering athletes through training and development programs, is a proud sponsor of the UPKL. As a security partner, their support ensures that players and spectators enjoy a safe and secure environment, allowing athletes to focus on excelling in the sport of kabaddi.





</p>
              </div>
            </div>
          </div>
          {/* End M Power Sponsor Section */}



  {/* Modair Sponsor Section */}
  <div className="titlecaption">
            <div className="d-flex">
              <div className="newarrows">
                <h2 className="text-white">The Mod Store, Modish Ombre, Modmobile: Elevating Performance in UPKL</h2>
                <div className="linetilte"></div>
              </div>
              
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 col-12 align-self-center">
              <div className="aboutuspara">
                <p>
                Modish Ombre, a gifting partner of the Uttar Pradesh Kabaddi League (UPKL). By providing cutting-edge mobile devices and accessories, Modish Ombre enhances the experience for players, officials, and fans. This partnership adds innovation and sophistication to UPKL events, boosting the league's prestige and broadening Modish Ombre's brand visibility among a diverse audience.





</p>
              </div>
            </div>
          </div>
          {/* End Modair Sponsor Section */}
 




          {/* Narayana Hospitals Sponsor Section */}
          <div className="titlecaption">
            <div className="d-flex">
              <div className="newarrows">
                <h2 className="text-white">Wildcard Tickets : Facilitating Seamless Access to UPKL</h2>
                <div className="linetilte"></div>
              </div>
              
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 col-12 align-self-center">
              <div className="aboutuspara">
                <p>
                Wildcard Tickets, as the official tickets partner of the Uttar Pradesh Kabaddi League (UPKL), plays a crucial role in facilitating seamless access to UPKL matches for fans. Their expertise in ticketing services ensures efficient and hassle-free ticket distribution, enhancing the overall fan experience. By partnering with Wildcard Tickets, UPKL can effectively manage attendance, streamline entry processes, and maintain crowd control during matches. This collaboration not only improves logistical operations but also increases accessibility and convenience for kabaddi enthusiasts, thereby boosting attendance and overall engagement with the league.                </p>
              </div>
            </div>
          </div>
          {/* End Narayana Hospitals Sponsor Section */}

       
      


      
       


          {/* Narayana Hospitals Sponsor Section */}
          <div className="titlecaption">
            <div className="d-flex">
              <div className="newarrows">
                <h2 className="text-white">Narayana Hospitals: Championing Wellness in UPKL</h2>
                <div className="linetilte"></div>
              </div>
              
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 col-12 align-self-center">
              <div className="aboutuspara">
                <p>
                  Narayana Hospitals, known for their exceptional medical services, join the UPKL as a sponsor to promote wellness and fitness. Their involvement emphasizes the importance of health in sports, offering medical assistance and health tips to players and fans alike.
                </p>
              </div>
            </div>
          </div>
          {/* End Narayana Hospitals Sponsor Section */}

       

          {/* Additional Sponsor Sections Can Be Added Here */}

        </div>


      </section>

      <Footer />
    </>
  );
}
