import React, { useState, useEffect } from "react";
import Footer from "../Include/Footer1";
import Nav from "../Include/Nav";
import teamlogo from "../footerlogo.svg";
import arrow from "../matcharrow.svg";
import arrow1 from "../arrow.svg";
import { Helmet } from "react-helmet";
import CompletedMatches from "./Completed";
import NewMatches from "./NewMatches";
function Matches() {
  const breadcumb = "https://dev-upkl.upkl.in//files/breadcumb1.png";
  const [matches, setMatches] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const fetchMatches = async () => {
      try {


        const currentDate ='2024-07-12';

        const response = await fetch(
          `https://dev-upkl.upkl.in/api/resource/FIXTURES?fields=["*"]&limit_page_length=100&filters=[["match_date","<=","${currentDate}"]]`
        );
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const data = await response.json();

        // Sort by position field
        const sortedData = data.data.sort(
          (a, b) => a.match_number - b.match_number
        );

        setMatches(sortedData);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchMatches();
  }, []);

  // Group matches by match_date
  const groupMatchesByDate = (matches) => {
    return matches.reduce((acc, match) => {
      const matchDate = new Date(match.match_date).toDateString();
      if (!acc[matchDate]) {
        acc[matchDate] = [];
      }
      acc[matchDate].push(match);
      return acc;
    }, {});
  };

  const groupedMatches = groupMatchesByDate(matches);

  return (
    <>
      <Helmet>
        <title> Upcoming Kabaddi Matches Schedule | UPKL Matches at Noida Indoor Stadium</title>
        <meta
          name="title"
          content="Upcoming Kabaddi Matches Schedule | UPKL Matches at Noida Indoor Stadium"
        />
         <meta
          name="keyword"
          content="upcoming kabaddi matches schedule UPKL matches Noida indoor stadium"
        />
        <meta
          name="description"
          content="Stay updated with the latest upcoming Kabaddi matches schedule at the Noida Indoor Stadium. Explore all the thrilling UPKL matches, dates, and venues. Don't miss a moment of the action"
        />
        <link rel="canonical" href="http://upkl.in/matches" />
      </Helmet>
      <Nav />

      
      <section className="breadcrumb">
        <img src={breadcumb} className="img-fluid" alt="UPKL Matches" />
        <div className="breadcrumb-caption">
          <div className="d-flex">
            <div className="newarrows">
              <h2 className="text-white">MATCHES</h2>
              <div className="linetilte"></div>
            </div>
            <div className="abc">
              <img src={arrow1} className="textrightarrow" alt="UPKL Matches" />
            </div>
          </div>
        </div>
      </section>
      <section>
       

      <div class="container-fluid matchstrip1">
  <ul class="nav nav-tabs" id="myTab" role="tablist">
    <li class="nav-item">
      <a class="nav-link active" id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true">UPCOMING / LIVE MATCHES</a>
    </li>
    <li class="nav-item">
      <a class="nav-link" id="profile-tab" data-toggle="tab" href="#profile" role="tab" aria-controls="profile" aria-selected="false">COMPLETED MATCHES</a>
    </li>
  </ul>
  <div class="tab-content" id="myTabContent">
    <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
      <NewMatches/>
    </div>
    <div class="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
    <CompletedMatches/>
    </div>
  </div>
</div>




      </section>
      <Footer />
    </>
  );
}
export default Matches;
