import React, { useState } from "react";
import Nav from "../Include/Nav";
import Footer from "../Include/Footer1";
import arrow from "../arrow.svg";
import founder from "../UPKL FOUNDER (3).jpg";
import { Helmet } from "react-helmet";
import auctioning from "./27-june-auction-thumbnail (1).jpg";
import partnerimg from "./broadcasters logo 02.jpg";
import pl1 from "./1 (1).jpeg";
import pl2 from "./1 (2).jpeg";
import pl3 from "./1 (3).jpeg";
import pl4 from "./1 (4).jpeg";
import pl5 from "./1 (5).jpeg";
import pl6 from "./1 (6).jpeg";
import pl7 from "./1 (7).jpeg";
import pl8 from "./1 (8).jpeg";
import pl9 from "./mm.png";
import auction1 from './AUCT (1).jpg'
import auction2 from './AUCT (2).jpg'
import auction3 from './AUCT (3).jpg'
import auction4 from './AUCT (4).jpg'
import auction5 from './AUCT (5).jpg'
import auction6 from './AUCT (6).jpg'
import auction7 from './AUCT (7).jpg'
import auction8 from './AUCT (8).jpg'
import auction9 from './AUCT (9).jpg'
import auction10 from './AUCT (10).jpg'
import auction11 from './AUCT (11).jpg'
import auction12 from './AUCT (12).jpg'
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
export default function Auction({ thumbnail, videoUrl }) {
  const [isPlaying, setIsPlaying] = useState(false);


  const auctionImages = [
    auction1,
    auction2,
    auction3,
    auction4,
    auction5,
    auction6,
    auction7,
    auction8,
    auction9,
    auction10,
    auction11,
    auction12
  ];


  const handleThumbnailClick = () => {
    setIsPlaying(true);
  };

  const options = {
    loop: true,
    margin:30,
    nav: false,
    loop: true,
    autoplay: true, // Enable autoplay
    autoplayTimeout: 3000, // Autoplay interval in milliseconds (4 seconds)
    responsive: {
      0: {
        items: 2.3,
        margin: 20,
      },
      600: {
        items: 3,
      },
      1000: {
        items: 4.5,
      },
    },
  };

  const breadcumb = "https://dev-upkl.upkl.in/files/breadcumb1.png";
  return (
    <>
      <Helmet>
        <title>
          {" "}
          Uttar Pradesh Kabaddi League (UPKL) - Empowering Kabaddi Athletes.
        </title>
        <meta
          name="title"
          content=" Uttar Pradesh Kabaddi League (UPKL) - Empowering Kabaddi Athletes."
        />

        <meta
          name="description"
          content="Learn about the Uttar Pradesh Kabaddi League (UPKL), a dedicated platform committed to nurturing local Kabaddi talents. Understand UPKL's mission to engage audiences and reinforce Kabaddi's significance in India."
        />

        <link rel="canonical" href="http://upkl.in/about" />
      </Helmet>

      <Nav />
      <section className="breadcrumb">
        <img src={breadcumb} className="img-fluid" alt="Breadcrumb Image" />
        <div className="breadcrumb-caption">
          <div className="d-flex">
            <div className="newarrows">
              <h2 className="text-white">AUCTION</h2>
              <div className="linetilte"></div>
            </div>
            <div className="abc">
              <img src={arrow} className="textrightarrow" />
            </div>{" "}
          </div>
        </div>
      </section>
      <section className="aboutsection">
        <div className="container-fluid">
          <div className="titlecaption">
            <div className="d-flex">
              <div className="newarrows">
                <h2 className="text-white">UPKL Mega Auction 2024: Timeline</h2>
                <div className="linetilte"></div>
              </div>
              <div className="abc">
                <img src={arrow} className="textrightarrow" />
              </div>{" "}
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 col-12">
              <div className="whoweareleft">
                <div className="video-container">
                    <img
                      src={auctioning}
                      alt="Video Thumbnail"
                      className="img-fluid"
                      data-toggle="modal"
                      data-target="#exampleModalCenter"
                    />
                </div>
              </div>
            </div>

            <div className="col-md-6 col-12 align-self-center">
              <div className="aboutuspara">
                <p>
                  The UPKL - Uttar Pradesh Kabaddi League 2024 had its first
                  ever Mega Auction 2024 after the Inaugural Press Conference
                  happened on 27th May 2024. 8 teams were decided and given a
                  green flag for the league. The teams are Awadh Ramdoots, Brij
                  Stars, JD Noida Ninjas, Kashi Kings, Ganga Kings of Mirzapur,
                  Lucknow Lions, Sangam Challengers, Yamuna Yodhas. 350 players
                  participated for the League Auction, and above mentioned teams
                  chose their players according to their strength, experience
                  and expertise. Three players namely, Arun Deshwal, Vinay,
                  Abhijeet Malik stood out as the most expensive players of the
                  entire auction.
                </p>
                <p>
                  This Mega auction happened to select the players batch for the
                  respective teams of UPKL that is all set to start from the
                  date 11th July-25th July on <b className="highlightword"></b>
                  Sony Liv App organized by 1X sports, radio partner: Red fm
                  93.5, Broadcast partner: The Bridge.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="aboutseparate"></div>
        <div className="container-fluid">
          <div className="titlecaption">
            <div className="d-flex">
              <div className="newarrows">
                <h2 className="text-white">TOP PICKS</h2>
                <div className="linetilte"></div>
              </div>
              <div className="abc">
                <img src={arrow} className="textrightarrow" />
              </div>{" "}
            </div>
          </div>

          <div className="row">
            <OwlCarousel className="owl-theme" {...options}>
              <div className="items">
                <img src={pl1} className="img-fluid" />
              </div>

              <div className="items">
                <img src={pl2} className="img-fluid" />
              </div>

              <div className="items">
                <img src={pl3} className="img-fluid" />
              </div>

              <div className="items">
                <img src={pl4} className="img-fluid" />
              </div>
              <div className="items">
                <img src={pl5} className="img-fluid" />
              </div>
              <div className="items">
                <img src={pl6} className="img-fluid" />
              </div>
              <div className="items">
                <img src={pl7} className="img-fluid" />
              </div>
              <div className="items">
                <img src={pl8} className="img-fluid" />
              </div>
              <div className="items">
                <img src={pl9} className="img-fluid" />
              </div>


            </OwlCarousel>
          </div>
        </div>
      </section>

      <section></section>
      {/* 
      <section className="videoplayer">
        <div className="custom-container">
          <div className="custom-background-image"></div>
          <div className="custom-overlay"></div>
          <div className="custom-content">
            <h1>
              WATCH THE AMAZING <br></br>TRAINING ATMOSPHERE<br></br>AT OUR CLUB
              WITH TALENT COACHES
              UPKL 2024 FOR DELCATRATION ALL BIGS SCC
            </h1>
          </div>
          <div className="custom-play-button">
            <img src={icon} className="img-fluid" />
          </div>
        </div>
      </section> */}

      <section className="aboutfounder">
        <div className="container-fluid">
          <div className="titlecaption">
            <div className="d-flex">
              <div className="newarrows">
                <h2 className="text-white">
                  Show Stoppers of the Mega Auction{" "}
                </h2>
                <div className="linetilte"></div>
              </div>
              <div className="abc">
                <img src={arrow} className="textrightarrow" />
              </div>{" "}
            </div>
          </div>

          <div className="founderbox">
            <div className="row">
              <div className="col-md-12 col-12 align-self-center">
                <div className="foundermessage foundermessage1">
                  <p>
                    10th June, 2024, an auction took place in Sarovar Hotel,
                    Noida from morning to evening.The UPKL Mega Auction indeed
                    was a power packed and money raining event. Event was graced
                    by the owners of UPKL itself, the team owners of UPKL, media
                    partners, brand ambassadors, radio partners, broadcast
                    partners and Players to be auctioned. The event started with
                    welcoming guests, and a few words from the owners of the
                    league.
                  </p>
                  <p>
                    The auction was rolled out in 4 clusters namely, Team A,
                    Team B, Team C and Team D. Team A consisted of players that
                    were priced at 1 lac with an increment 500,, Team B
                    consisted of players whose slab were 60K - 75 players with
                    an increment price 1000, Team C consisted of 225 players
                    were priced at 40K with increment price of 1000, Team D
                    consisted of 50 players who were priced at the base value
                    25K with an increment value as team B and team C.
                  </p>
                  <p>
                    Through strategic partnerships and concerted efforts, we are
                    committed to not only showcasing the incredible athleticism
                    and strategy inherent in Kabaddi but also instilling a
                    deep-seated love for the sport among fans across Uttar
                    Pradesh and beyond.
                  </p>
                  <p>
                    In the Auction, the top level players have been bidded and
                    chosen by enthusiastic owners. Amongst which top 5 are —
                    <b className="highlightword">Arjun Deshwal </b> closed at
                    310K taken by Lucknow Lions ,{" "}
                    <b className="highlightword">Vinay</b> closed at 310K
                    acquired by Yamuna Yodhas,{" "}
                    <b className="highlightword">Abhijeet Malik</b> finalised at
                    260K taken by Sangam Challengers,{" "}
                    <b className="highlightword">Ashu Singh</b> closed at 150K
                    taken by Awadh Ramdoots,{" "}
                    <b className="highlightword">Amit Nagar </b> closed at 145K
                    acquired by Noida Ninjas.
                  </p>

                  <div className="founderline"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="aboutfounder">
      <div className="titlecaption">
            <div className="d-flex">
              <div className="newarrows">
                <h2 className="text-white">
              Auction Gallery
                </h2>
                <div className="linetilte"></div>
              </div>
              <div className="abc">
                <img src={arrow} className="textrightarrow" />
              </div>{" "}
            </div>
          </div>
<br></br>
        <div className="container-fluid">
         <div className="row">
      {auctionImages.map((image, index) => (
        <div className="col-6 col-md-3" key={index}>
          <img src={image} className="img-fluid photoauction" alt={`Auction ${index + 1}`} />
        </div>
      ))}
    </div>
        </div>
      </section>
      <Footer />


      <div
        className="modal fade"
        id="exampleModalCenter"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-lg modal-dialog-centered"
          role="document"
        >
          <div className="modal-content">
            {/* <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLongTitle">UPKL Auction </h5>
        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div> */}
            <div className="modal-body">
              <iframe
                width="100%"
                src="https://www.youtube.com/embed/YjCJy0K42tA?si=CdSQi72mcgidfv1u"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerpolicy="strict-origin-when-cross-origin"
                allowfullscreen
              ></iframe>{" "}
            </div>
          </div>
        </div>
      </div>


    </>
  );
}
