// React Component
import React ,{useState,useEffect} from 'react';
import '../src/App.css';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Team from './Team/Team'
import Home from '../src/Components/Home'
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Matches from './Matches/Matches';
import SingleBlog from './Blog.js/SingleBlog';
import About from './About/About';
import Standing from './Standing/Standing';
import Bloglist from './Blog.js/Bloglist'
import News from './New Page/New';
import Auction from './Auction/Auction';
import Lucknow from './CityTour/Lucknow';
import Tv from './Components/Tv';
import Arodhya from './CityTour/Arodhya';
import Kanpur from './CityTour/Kanpur';
import Mirzapur from './CityTour/Mirzapur';
import Prayagraj from './CityTour/Prayagraj';
import Kashi from './CityTour/Kashi';
import Agra from './CityTour/Agra';
import Jashi from './CityTour/Jashi';
import Noida from './CityTour/Noida';
import Mathura from './CityTour/Mathura';
import Score from './Score';
import Stats from './Stats';
import Sponsor from './Sponsor/Sponsor';
import TeamMatch from './TeamMatches/Matches'
import Photo from './Gallery/Photo';
import Photodetails from './Gallery/Photodetails';
import Players from './Players/Players';
import { Analytics } from '@vercel/analytics/react';
import Playerdetails from './Players/Playerdetail';
import { useLocation } from "react-router-dom";
import SingleBlog2 from './Blog.js/SingleBlog2';

const App = () => {

  return (
    <>

<Analytics />

<BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />}/>
        <Route path="/team" element={<Team/>} />
        <Route path="/matches" element={<Matches/>} />
        <Route path="/single-blog" element={<SingleBlog/>} />
        <Route path="/standing" element={<Standing/>} />
        <Route path="/about" element={<About/>} />
        <Route path="/blog-list" element={<Bloglist/>} />
        <Route path="/news" element={<News/>} />
        <Route path="/auction" element={<Auction/>} />
        <Route path="/lucknow" element={<Lucknow/>} />
        <Route path="/ayodhya-tour" element={<Arodhya/>} />
        <Route path="/kanpur-tour" element={<Kanpur/>} />
        <Route path="/prayagraj-tour" element={<Prayagraj/>} />
        <Route path="/mirzapur-tour" element={<Mirzapur/>} />
        <Route path="/kashi-tour" element={<Kashi/>} />
        <Route path="/agra-tour" element={<Agra/>} />
        <Route path="/jashi-tour" element={<Jashi/>} />
        <Route path="/noida-tour" element={<Noida/>} />
        <Route path="/mathura-tour" element={<Mathura/>} />
        <Route path="/score" element={<Score/>} />
        <Route path="/teammatch" element={<TeamMatch/>} />
        <Route path="/stats" element={<Stats/>} />
        <Route path="/sponsor" element={<Sponsor/>} />
        <Route path="/gallery" element={<Photo/>} />
        <Route path="/photodetails" element={<Photodetails/>} />
        <Route path="/players" element={<Players/>} />
        <Route path="/playerdetail" element={<Playerdetails/>} />
        <Route path="/blog-detail" element={<SingleBlog2/>} />








        




        <Route path="/upkltv" element={<Tv/>} />





        
      </Routes>
    </BrowserRouter>
    </>
  );
}

export default App;
