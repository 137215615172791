import React, { useState, useEffect } from "react";
import Nav from "../Include/Nav";
import arrow from "../arrow.svg";
import { Helmet } from "react-helmet";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import Footer from "../Include/Footer1";
const breadcumb = "https://dev-upkl.upkl.in/files/breadcumb1.png";

// Function to shuffle an array
const shuffleArray = (array) => {
  let shuffledArray = array.slice();
  for (let i = shuffledArray.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
  }
  return shuffledArray;
};

export default function GalleryPlayer() {
  const [standings, setStandings] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchStandings = async () => {
      try {
        const response = await fetch(
          'https://dev-upkl.upkl.in/api/method/get_gallery?records=50'
        );
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const data = await response.json();
        console.log("Fetched data:", data); // Log the fetched data
        const shuffledData = shuffleArray(data.test); // Shuffle the data
        setStandings(shuffledData); // Ensure the correct property is set here
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchStandings();
  }, []);

  const options3 = {
    loop: true,
    margin: 20,
    nav: false,
    dots: false,
    autoplay: true,
    autoplayTimeout: 4200,
    responsive: {
      0: {
        items: 1.1,
      },
      600: {
        items: 3,
      },
      1000: {
        items: 3.2,
      },
    },
  };

  return (
    <>
      <section className="newpage">
        <div className="titlecaption">
          <div className="d-flex">
            <div className="newarrows">
              <h2 className="text-white">UPKL Gallery </h2>
              <div className="linetilte mb-2"></div>
            </div>
            <div className="abc">
              <img src={arrow} className="textrightarrow" alt="UPKL news" />
            </div>
          </div>
        </div>
        {loading ? (
          <SkeletonTheme baseColor="#292679" highlightColor="#39358b70">
            <Skeleton width={260} height={400} />{" "}
          </SkeletonTheme>
        ) : (
          <OwlCarousel className="owl-theme" {...options3}>
            {standings.length > 0 ? (
              standings.map((imagePath, index) => (
                <div className="card" key={index}>
                  <img
                    src="/static/media/borderblog.21867030a71636afa32cfd31be23043a.svg"
                    className="img-fluid bordernew"
                    alt="UPKL news"
                  />
                  <img
                    className="card-img-top"
                    src={`https://dev-upkl.upkl.in${imagePath}`}
                    alt="UPKL news"
                  />
                </div>
              ))
            ) : (
              <p>No data available</p>
            )}
          </OwlCarousel>
        )}
      </section>
    </>
  );
}
