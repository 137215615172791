import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import Footer from "../Include/Footer1";
import Nav from "../Include/Nav";
import teamlogo from "../footerlogo.svg";
import arrow from "../matcharrow.svg";
import arrow1 from "../arrow.svg";
import { useLocation } from 'react-router-dom';




function NewMatches() {

  
const location = useLocation();
const queryParams = new URLSearchParams(location.search);
const teamname = queryParams.get('team');


  const [matches, setMatches] = useState([]);
  const [loading, setLoading] = useState(true);
  const now = new Date();
  const currentHour = now.getHours();
  const currentMinutes = now.getMinutes();
  const currentDay = now.getDate();
  const currentMonth = now.getMonth() + 1; // getMonth is zero-indexed
  const currentYear = now.getFullYear();

  const liveMatchStartHour = 10;
  const liveMatchEndHour = 11; // Changed to 20 for 8:00 PM

  const isLiveMatch =
    currentHour >= liveMatchStartHour &&
    currentHour < liveMatchEndHour &&
    currentDay === now.getDate() &&
    currentMonth === now.getMonth() + 1 &&
    currentYear === now.getFullYear();

  const isMatchCompleted = currentHour >= liveMatchEndHour;

  useEffect(() => {
    const fetchMatches = async () => {
      try {
        const currentDate = `${currentYear}-${currentMonth}-${currentDay}`;
        const response = await fetch(

          `https://dev-upkl.upkl.in/api/resource/FIXTURES?or_filters=[["team_a_name", "=", "${teamname}"], ["team_b_name", "=", "${teamname}"]]&filters=[["match_date", ">=", "${currentDate}"],["status", "!=", "Full-Time"]]&fields=["*"]&limit_page_length=100&order_by=match_number ASC`

          // `https://dev-upkl.upkl.in/api/resource/FIXTURES?fields=["*"]&limit_page_length=100&filters=[["team_a_name", "=", "KASHI KINGS"], ["team_b_name", "=", "KASHI KINGS"],["match_date",">=","${currentDate}"]]`
        );
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const data = await response.json();

        // Sort by match_date and match_time
        const sortedData = data.data.sort((a, b) => {
          if (a.match_date !== b.match_date) {
            return a.match_date.localeCompare(b.match_date);
          } else {
            return a.match_time.localeCompare(b.match_time);
          }
        });

        setMatches(sortedData);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchMatches();
  }, []);

  // Group matches by match_date
  const groupMatchesByDate = (matches) => {
    return matches.reduce((acc, match) => {
      const matchDate = new Date(match.match_date).toDateString();
      if (!acc[matchDate]) {
        acc[matchDate] = [];
      }
      acc[matchDate].push(match);
      return acc;
    }, {});
  };

  const groupedMatches = groupMatchesByDate(matches);

  return (
    <>
      <Helmet>
        <title>New Matches</title>
      </Helmet>
      <section>
        <div className="matchstrip">
          <div className="container-fluid">
            {loading ? (
              <p className="text-white">Loading...</p>
            ) : (
              Object.keys(groupedMatches).map((date) => (
                <div key={date}>
                  <div className="titlecaption">
                    <h2 className="matchhead">{date}</h2>
                    <div className="match-status">
                      {/* {date === new Date().toDateString() &&
                      isLiveMatch ? (
                        <div className="livmatch"><p>Live Match</p></div>
                      ) : isMatchCompleted ? (
                        <div className="compmatch"><p>Match Completed</p></div>
                      ) : (
                        <div className="compmatch"><p>Upcoming Match</p></div>
                      )} */}
                    </div>
                    <div className="linetilte"></div>
                  </div>
                  {groupedMatches[date].map((match, index) => (
                    <Link
                      to={`/score?matchid=${encodeURIComponent(match.match_id)}`}
                      key={match.match_id}
                    >
                      <div className="matchesbox">
                        <div className="row">
                          <div className="col-md-1 col-12 align-self-center">
                            <div className="matchleft">
                              <h5 className="finalmatch">
                                MATCH {match.match_day}
                              </h5>
                            </div>
                            <div className="matchseparator desktopnone"></div>
                          </div>
                          <div className="col-md-8 col-12">
                            <div className="matchcenter">
                              <div className="row">
                                <div className="col-md-2 col-2 align-self-center">
                                  <img
                                    src={`https://dev-upkl.upkl.in/${match.team_a_logo}`}
                                    className="float-right img-fluid"
                                    alt={match.team_a_name}
                                  />
                                </div>
                                <div className="col-3 align-self-center">
                                  <div className="teamname">
                                    <h6>{match.team_a_name}</h6>
                                  </div>
                                </div>
                                <div className="col-md-2 col-2 p-0 p-sm-0 align-self-center">
                                  <div className="match-score-tl">
                                    {/* <h5>--</h5> */}
                                    {match.status === 'Live' && (
  <p className="MatchLive"> <span className="dotslive"></span><span className="livetxt">LIVE</span></p>
)}
{match.status === 'Full-Time' && (
  <p className="fulltime">Full Time</p>
)}
{match.status === null && (
<></> 
)}
                                  </div>
                                  <div className="match-score">
                                  <h5> {match.team_a_score ? ` ${match.team_a_score}-${match.team_b_score}` : 'VS'}</h5>
                                  </div>
                                </div>
                                <div className="col-3 align-self-center">
                                  <div className="teamname">
                                    <h6>{match.team_b_name}</h6>
                                  </div>
                                </div>
                                <div className="col-md-2 col-2 align-self-center">
                                  <img
                                    src={`https://dev-upkl.upkl.in/${match.team_b_logo}`}
                                    className="float-left img-fluid"
                                    alt={match.team_b_name}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-3 col-12 align-self-center">
                            <div className="row">
                              <div className="col-md-8 col-12 p-0">
                                <div className="matchright">
                                  <p>
                                    Noida Indoor Stadium, Noida <br />
                                    <b>(Time : {match.match_time} )</b>
                                  </p>
                                </div>
                              </div>
                              <div className="col-4 d-none d-md-block d-lg-block">
                                <center>
                                  <div className="matchlogotop">
                                    <div className="matchlogo">
                                      <img
                                        src={teamlogo}
                                        alt="Match Logo"
                                      />
                                    </div>
                                  </div>
                                </center>
                                <img
                                  src={arrow}
                                  className="img-fluid matcharrow"
                                  alt="Arrow"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  ))}
                </div>
              ))
            )}
          </div>
        </div>
      </section>
    </>
  );
}

export default NewMatches;
