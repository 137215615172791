import React from "react";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Nav from "../Include/Nav";
import Footer from "../Include/Footer1";
import "react-loading-skeleton/dist/skeleton.css";
import arrow1 from "../arrow.svg";
import StandingsTable from "../Components/StandingsTable";
import { Helmet } from "react-helmet";

const Standing = () => {
  const breadcumb = "https://dev-upkl.upkl.in//files/breadcumb1.png";
  return (
    <>
      <Helmet>
        <title>
          UP Kabaddi League Results & Scores | UPKL Match Outcomes & Points
          Table
        </title>
        <meta
          name="title"
          content="UP Kabaddi League Results & Scores | UPKL Match Outcomes & Points Table"
        />
        <meta
          name="keywords"
          content="UP Kabaddi League results Uttar Pradesh Kabaddi scores UPKL match outcomes pro kabaddi league points table "
        />

        <meta
          name="description"
          content="Check the latest UP Kabaddi League results, Uttar Pradesh Kabaddi scores, and UPKL match outcomes. Stay informed with the updated Pro Kabaddi League points table and standings"
        />

        <link rel="canonical" href="https://upkl.in/standing" />
      </Helmet>
      <Nav />
      <section className="breadcrumb">
        <img
          src={breadcumb}
          className="img-fluid"
          alt="UP Kabaddi League results
Uttar Pradesh Kabaddi scores
UPKL match outcomes
pro kabaddi league points table
"
        />
        <div className="breadcrumb-caption">
          <div className="d-flex">
            <div className="newarrows">
              <h2 className="text-white">STANDINGS</h2>
              <div className="linetilte"></div>
            </div>
            <div className="abc">
              <img
                src={arrow1}
                className="textrightarrow"
                alt="UP Kabaddi League results
Uttar Pradesh Kabaddi scores
UPKL match outcomes
pro kabaddi league points table
"
              />
            </div>{" "}
          </div>
        </div>
      </section>
      <StandingsTable />
      <Footer />
    </>
  );
};

export default Standing;
