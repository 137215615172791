import React ,{useEffect}from "react";
import inst from "../insta.svg";
import you from "../youtube.svg";
import twitter from "../twitter.svg";
import songsvg from "../sony logo.png";
import redfm from "../red fm logo.png";
import bridge from "../bridge.png";
import sportz from "../sportz.png";
import arrow from '../arrow.svg'
import ln from './ln.svg'
import bet from './partner/1x-bet.png'
import fun from './partner/fun-88-logo.png'
import hustle from './partner/hustle-logo.png'
import kabadi360 from './partner/kabaddi-360.png'
import kn from './partner/kn-kabaddi.png'
import sonylogonew from './partner/sony-logo.png'
import tedhe from './partner/tedhe-medhe (2).png'
import wildcard from './partner/wildcard.png'
import gadi from './partner/ghadi-logo.png'
import narayan  from './partner/narayan-health-care.png'
import daram from './partner/dharamshila-and-narayana-logo.png'

import homepartner from './New-Screen-001-oswald.jpg'
import homepartnermob from './mobile-cover-02.jpg'

// import gadi from './partner/ghadi-logo.png'

import  cricket from './cricbattle-01 (1).png'
import  fancop from './fan-code-logo (1).png'

import { Link, useLocation } from 'react-router-dom';


export default function Footer() {

  const location = useLocation();

  useEffect(() => {
    // Parse the URL parameters
    const searchParams = new URLSearchParams(location.search);
    const idParam = searchParams.get('id');

    if (idParam) {
      // Scroll to the section with the specified ID
      const section = document.getElementById(idParam);
      if (section) {
        section.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location.search]);




  const footerlogo = "https://dev-upkl.upkl.in/files/logoupkl.png";
  const logo1 = "https://dev-upkl.upkl.in/files/noida1.png";
  const logo2 = "https://dev-upkl.upkl.in/files/kashi1.png";
  const logo3 = "https://dev-upkl.upkl.in/files/Ganga1 (1).png";
  const logo4 = "https://dev-upkl.upkl.in/files/Yamuna1.png";
  const logo5 = "https://dev-upkl.upkl.in/files/awadh1.png";
  const logo6 = "https://dev-upkl.upkl.in/files/Brij 1.png";
  const logo8 = "https://dev-upkl.upkl.in/files/lucknow1.png";
  const logo7 = "https://dev-upkl.upkl.in/files/sangam1.png";
  return (
    <>
      <section className="sponsorsection" id="sponsor">
        <div className="sponsor">
        <div className='titlecaption'>
        <div className='d-flex'>
          <div className='newarrows'>
          <h2 className='text-white'>PARTNERS</h2>
          <div className='linetilte'></div>

          </div>
          <div className='abc'>
            <img src={arrow} className='textrightarrow' alt="UPKL 2024"/></div>  </div>
        </div>
          <div className="container-fluid">
            <div className="row">
             
              {/* <div className="col-md-2 col-6 vertical-divider ">
                <div className="title">
                  <h6>TITLE PARTNER</h6>
                  <img src={tedhe} className="img-fluid" alt="UPKL 2024" />
                </div>
              </div>
              <div className="col-md-2 col-6 vertical-divider">
                <div className="title">
                  <h6>C0-POWERED PARTNER</h6>
                  <img src={gadi} className="img-fluid" alt="UPKL 2024" />
                </div>
              </div>


              <div className="col-md-2 col-6 vertical-divider ">
                <div className="title">
                  <h6>SUPPORTING PARTNER</h6>
                  <img src={bet} className="img-fluid" alt="UPKL 2024" />
                </div>
              </div>

              <div className="col-md-2 col-6 vertical-divider ">
                <div className="title">
                  <h6>ENERGY DRINK PARTNER</h6>
                  <img src={hustle} className="img-fluid" alt="UPKL 2024" />
                </div>
              </div>

              <div className="col-md-2 col-6 vertical-divider">
                <div className="title">
                  <h6>TICKET PARTNER</h6>
                  <img src={wildcard} className="img-fluid" alt="UPKL 2024" />
                </div>
              </div>

              <div className="col-md-2 col-6 vertical-divider ">
                <div className="title">
                  <h6>BROADCASTING
                  PARTNER</h6>
                  <img src={sonylogonew} className="img-fluid" alt="UPKL 2024" />
                </div>
              </div>


              <div className="col-md-2 col-6 ">
                <div className="title">
                  <h6>
                  OUTREACH  PARTNER</h6>
                  <img src={kn} className="img-fluid" alt="UPKL 2024" />
                </div>
              </div>

              <div className="col-md-2 col-6 vertical-divider ">
                <div className="title">
                  <h6>MEDIA PARTNER</h6>
                  <img src={bridge} className="img-fluid" alt="UPKL 2024" />
                </div>
              </div>

              <div className="col-md-2 col-6 vertical-divider ">
                <div className="title">
                  <h6>BROADCASTING PARTNER</h6>
                  <img src={songsvg} className="img-fluid" alt="UPKL 2024" />
                </div>
              </div>
              <div className="col-md-2 col-6 vertical-divider ">
                <div className="title">
                  <h6>RADIO PARTNER</h6>
                  <img src={redfm} className="img-fluid" alt="UPKL 2024" />
                </div>
              </div>{" "}

              <div className="col-md-2 col-6 vertical-divider ">
                <div className="title">
                  <h6>LEAGUE ORGANISER</h6>
                  <img src={sportz} className="img-fluid" alt="UPKL 2024" />
                </div>
              </div>


              <div className="col-md-2 col-6 vertical-divider ">
                <div className="title">
                  <h6>STREAMING PARTNER</h6>
                  <img src={fancop} className="img-fluid" alt="UPKL 2024" />
                </div>
              </div>



              <div className="col-md-2 col-6 vertical-divider">
                <div className="title">
                  <h6>AUCTION PARTNER</h6>
                  <img src={cricket} className="img-fluid" alt="UPKL 2024" />
                </div>
              </div> */}

              <img src={homepartner} className="img-fluid dshow" />
              <img src={homepartnermob} className="img-fluid mshow" />




            

{/*               
              <div className="col-md-2 col-6 ">
                <div className="title">
                  <h6>HEALTH CARE PARTNER</h6>
                  <img src={narayan} className="img-fluid" alt="UPKL 2024" />
                </div>
              </div> */}

              {/* <div className="col-md-2 col-6 ">
                <div className="title">
                  <h6>HEALTH CARE PARTNER</h6>
                  <img src={daram} className="img-fluid" alt="UPKL 2024" />
                </div>
              </div> */}

              

            
            </div>
          </div>
        </div>
      </section>
      <section>
        <footer>
          <div className="container footerline">
            <div className="row">
              <div className="col-md-6 col-12  align-self-center">
                <div className="footerrightmenu">
                  <ul className="d-flex p-0">
                    <li>
                      <img src={logo1} alt="UPKL 2024" />
                    </li>
                    <li>
                      <img src={logo2}  alt="UPKL 2024"/>
                    </li>
                    <li>
                      <img src={logo3} alt="UPKL 2024"/>
                    </li>
                    <li>
                      <img src={logo4} alt="UPKL 2024" />
                    </li>
                    <li>
                      <img src={logo5} alt="UPKL 2024" />
                    </li>
                    <li>
                      <img src={logo6} alt="UPKL 2024" />
                    </li>
                    <li>
                      <img src={logo7} alt="UPKL 2024" />
                    </li>
                    <li>
                      <img src={logo8} alt="UPKL 2024" />
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col-md-6 col-12 align-self-center mobilenone">
                <div className="socialmenu">
                  <ul className="d-flex p-0">
                    <li>
                      <Link  target="_blank" to="https://www.linkedin.com/in/uttar-pradesh-kabaddi-league-a10bbb288/?originalSubdomain=in">
                      <img src={ln} alt="UPKL 2024"/>
                      </Link>
                    </li>
                    <li>
                      <Link  target="_blank" to="https://www.instagram.com/upklofficial?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw==">
                      <img src={inst} alt="UPKL 2024"/>
                      </Link>
                    </li>
                    <li>

                  
                    <Link  target="_blank" to="https://youtube.com/@uttarpradeshkabaddileague?si=taFbMpC2ReUxpU59">
                    <img src={you} alt="UPKL 2024"/></Link>  
                    </li>
                    <li>
                      <Link target="_blank"  to="https://x.com/upkabaddileague/status/1794362732390883592?s=46">
                      <img src={twitter} alt="UPKL 2024"/>
                      </Link>

                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 col-12 offset-md-3 offset-0">
              <div className="footermenu">
                <div className="row">
                  <div className="col-md-2 col-4">
                  <Link  className="nav-link" to="/matches"> <p>MATCHES</p></Link>
                  </div>
                  <div className="col-md-2 col-4">
                  <Link  className="nav-link" to="/standing"> <p>STANDINGS</p></Link>
                  </div>
                  <div className="col-md-2 col-4">
                  <Link  className="nav-link" to="/team"><p>TEAMS</p></Link>
                  </div>
                  {/* <div className="col-md-2 col-4">
                  <Link  className="nav-link" to="/about"> <p>ABOUT US</p></Link>
                  </div> */}
                  <div className="col-md-2 col-4">
                  <Link  className="nav-link" to="#"><p>SPONSORS </p></Link>
                  </div>

                  <div
                   className="col-md-2 col-4">
                  <Link className="nav-link" to="/?id=rahul"><p> AMBASSADOR</p></Link>
                  </div>

                  <div
                   className="col-md-2 col-4">
                  <Link className="nav-link" to="/gallery"><p> GALLERY</p></Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-10 col-12 offset-md-1 offset-0">
            <div className="footerlogo">
              <img src={footerlogo} className="img-fluid" alt="UPKL 2024" />
              <p className="footerlast">
                © 2024 Uttar Pradesh Kabaddi League. All rights reserved
              </p>
              <div className="footerline"></div>
            </div>
            <p className="policy">
            Uttar Pradesh Kabaddi League is the official league of UP in union with governing body, Uttar Pradesh Kabaddi Association.            </p>
          </div>
        </footer>
      </section>
    </>
  );
}
