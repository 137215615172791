import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import l1 from  './Ayodhya/ayodhya-01.jpg'
import l2 from  './Ayodhya/ayodhya-02.jpg'
import l3 from  './Ayodhya/ayodhya-03.jpg'
import arrow from "../arrow.svg";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import arrow1 from "../arrow.svg";
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Footer from '../Include/Footer1';
import Nav from '../Include/Nav';
const breadcumb = "https://dev-upkl.upkl.in/files/breadcumb1.png";
const Arodhya = () => {
  const [activeCity, setActiveCity] = useState('ayodhya');
  const options = {
    loop: true,
    margin:10,
    nav: false,
    responsive: {
        0: {
            items: 1.4,
            margin: 5,
        },
        600: {
            items: 3
        },
        1000: {
            items: 4
        }
    }
};


  const renderContent = () => {
    switch (activeCity) {
      case 'lucknow':
        return (
          <>
            <div className="col-12 col-md-4">
              <div className="trophy-content">
                <h5>NOIDA TROPHY TOUR</h5>
                <p>
                  Kabaddi fever has hit Lucknow! The Uttar Pradesh Kabaddi League (UPKL) Trophy Tour began its journey in the heart of Uttar Pradesh, and what a spectacular start it was. The Lucknow Lions, the pride of the city, welcomed the trophy with grandeur and excitement. Fans thronged the event, showcasing their support with banners, chants, and traditional dance performances. The iconic Bara Imambara served as a stunning backdrop, symbolizing the historic significance of the city and its modern sporting aspirations. This kickoff event set the tone for the thrilling journey ahead, highlighting Lucknow's passion for kabaddi.
                </p>
              </div>
            </div>
            <div className="col-12 col-md-4"></div>
            <div className="col-12 col-md-4">
              <div className="trophy-content2">
                <h5>Divinity of Ayodhya</h5>
                <p>
                  The UPKL Trophy Tour was not just about displaying the gleaming silverware; it was a celebration of the sport and its deep-rooted connection with the people of Uttar Pradesh. As the trophy traversed through each city, it was met with immense enthusiasm, colourful festivities, and an unwavering love for kabaddi.
                </p>
              </div>
            </div>
          </>
        );
      case 'ayodhya':
        return (
          <>
            <div className="col-12 col-md-4">
              <div className="trophy-content">
                <h5>Spiritual Heritage Meets Sports in Ayodhya with the Awadh Ramdoots</h5>
                <p>
                Ayodhya, renowned for its deep spiritual heritage, hosted the UPKL Trophy Tour with an awe-inspiring reception. The Awadh Ramdoots, representing the city, put together a grand spectacle. The trophy was witnessed and celebrated through the city with traditional music and dance, culminating in a ceremony at the Ram Janmabhoomi.
                </p>
              </div>
            </div>
            <div className="col-12 col-md-4"></div>
            <div className="col-12 col-md-4">
              <div className="trophy-content2">
              <h5>Divinity of Ayodhya</h5>
                <p>
                This event symbolized Ayodhya's historic and cultural significance, blending spirituality with sportsmanship. The celebration showcased Ayodhya's devotion to both its spiritual roots and its emerging sports culture, making it a memorable stop on the tour.
                </p>
              </div>
            </div>
          </>
        );
      // Add more cases for other cities...
      default:
        return null;
    }
  };

  return (
    <>
<Nav/>

<section className="breadcrumb">
        <img src={breadcumb} className="img-fluid" alt="Trophy Tour UPKL" />
        <div className="breadcrumb-caption">
          <div className="d-flex">
            <div className="newarrows">
              <h2 className="text-white">TROPHY  TOUR</h2>
              <div className="linetilte"></div>
            </div>
            <div className="abc">
              <img src={arrow1} className="textrightarrow" alt="Trophy Tour UPKL" />
            </div>
          </div>
        </div>
      </section>

      <section className="stripcity">
        <div className="trophy-city">
          <ul className="d-flex">
             <li className={activeCity === 'lucknow' ? 'touractive' : ''}><Link to="/lucknow" onClick={() => setActiveCity('lucknow')}>Lucknow</Link></li>
            <li className={activeCity === 'ayodhya' ? 'touractive' : ''}><Link to="/ayodhya-tour" onClick={() => setActiveCity('ayodhya')}>AYODHYA</Link></li>
            <li className={activeCity === 'kashi' ? 'touractive' : ''}><Link to="/kashi-tour" onClick={() => setActiveCity('kashi')}>Kashi</Link></li>
            <li className={activeCity === 'mirzapur' ? 'touractive' : ''}><Link to="/mirzapur-tour" onClick={() => setActiveCity('mirzapur')}>MIRZAPUR</Link></li>
            <li className={activeCity === 'prayagraj' ? 'touractive' : ''}><Link to="/prayagraj-tour" onClick={() => setActiveCity('prayagraj')}>PRAYAGRAJ</Link></li>
            <li className={activeCity === 'kanpur' ? 'touractive' : ''}><Link to="/kanpur-tour" onClick={() => setActiveCity('kanpur')}>KANPUR</Link></li>
            <li className={activeCity === 'jashi' ? 'touractive' : ''}><Link to="/jashi-tour" onClick={() => setActiveCity('jashi')}>Jhansi
</Link></li>
            <li className={activeCity === 'mathura' ? 'touractive' : ''}><Link to="/mathura-tour" onClick={() => setActiveCity('noida')}>Mathura</Link></li>

            <li className={activeCity === 'agra' ? 'touractive' : ''}><Link to="/agra-tour" onClick={() => setActiveCity('noida')}>Agra</Link></li>
            <li className={activeCity === 'noida' ? 'touractive' : ''}><Link to="/noida-tour" onClick={() => setActiveCity('noida')}>NOIDA</Link></li>
          </ul>
        </div>
      </section>
      <section className="trophysection">
        <div className="trophysection1">
          <div className="container-fluid">
            <div className="row">
              {renderContent()}
            </div>
          </div>
        </div>
      </section>

      <section className='fixturessection1'>
      <div className='titlecaption'>
                    <div className='d-flex'>
                        <div className='newarrows'>
                            <h2 className='text-white'> AYODHYA  GALLERY</h2>
                            <div className='linetilte'></div>
                        </div>
                        <div className='abc'>
                            <img src={arrow} className='textrightarrow' alt="Trophy Tour UPKL" />
                        </div>
                    </div>
                </div>

        <div className='container-fluid'>
          
        <OwlCarousel className="owl-theme" {...options}>
          <div className='itemluck'>
            <img src={l1} className='img-fluid' alt="Trophy Tour UPKL"/>
          </div>

          <div className='itemluck'>
            <img src={l2} className='img-fluid' alt="Trophy Tour UPKL"/>
          </div>


          <div className='itemluck'>
            <img src={l3} className='img-fluid' alt="Trophy Tour UPKL"/>
          </div>


          </OwlCarousel>
          
        </div>
      </section>


      <section className='fixturessection1'>
      <div className='titlecaption'>
                    <div className='d-flex'>
                        <div className='newarrows'>
                            <h2 className='text-white'> VIDEO</h2>
                            <div className='linetilte'></div>
                        </div>
                        <div className='abc'>
                            <img src={arrow} className='textrightarrow' alt="arrow" />
                        </div>
                    </div>
                </div>

        <div className='container-fluid'>
          
        <iframe width="100%" height="315" src="https://www.youtube.com/embed/O9Y8aSQ6xxw?si=0xN0QZhsfpt1i4q7" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>        
             </div>
      </section>
      <Footer/>
    </>
  );
};

export default Arodhya;
