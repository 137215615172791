import React, { useState, useEffect } from "react";
import Nav from "../Include/Nav";
import arrow from "../arrow.svg";
import { Helmet } from "react-helmet";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Link } from "react-router-dom";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import Footer from "../Include/Footer1";
const breadcumb = "https://dev-upkl.upkl.in/files/breadcumb1.png";



export default function Fixture() {
  const [standings, setStandings] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchStandings = async () => {
      try {
      const currentDate = `${currentYear}-${currentMonth}-${currentDay}`;
        const response = await fetch(`https://dev-upkl.upkl.in/api/resource/FIXTURES?limit_page_length=100&fields=[%22*%22]&order_by=match_day%20desc`
        );
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const data = await response.json();
        console.log("Fetched data:", data); // Log the fetched data
        const sortedData = data.data.sort((a, b) => b.match_day - a.match_day);
        setStandings(sortedData);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchStandings();
  }, []);

  const now = new Date();
  const currentHour = now.getHours();
  const currentMinutes = now.getMinutes();
  const currentDay = now.getDate();
  const currentMonth = now.getMonth() + 1; // getMonth is zero-indexed
  const currentYear = now.getFullYear();

  
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const dayOptions = { weekday: "long" };
    const dateOptions = { year: "numeric", month: "long", day: "numeric" };
    return {
      day: date.toLocaleDateString("en-US", dayOptions),
      date: date.toLocaleDateString("en-US", dateOptions),
    };
  };


  const options = {
    loop: true,
    margin: 10,
    nav: false,
    // autoplay: true, // Enable autoplay
    // autoplayTimeout: 3000, // Autoplay interval in milliseconds (3 seconds)
    responsive: {
      0: {
        items: 1.4,
        margin: 5,
      },
      600: {
        items: 3,
      },
      1000: {
        items: 4.5,
      },
    },
  };

  return (
    <>
      <section className="fixturessection">
        <div className="titlecaption">
          <div className="d-flex">
            <div className="newarrows">
              <h2 className="text-white">FIXTURES</h2>
              <div className="linetilte mb-2"></div>
            </div>
            <div className="abc">
              <img src={arrow} className="textrightarrow" alt="UPKL news" />
            </div>
          </div>
        </div>
        {loading ? (
          <SkeletonTheme baseColor="#292679" highlightColor="#39358b70">
            <Skeleton width={260} height={400} />{" "}
          </SkeletonTheme>
        ) : (
          <OwlCarousel className="owl-theme" {...options}>
          {standings.length > 0 ? (
            standings.map((team, index) => {
              const { day, date } = formatDate(team.match_date);
              return (

                <Link
                to={`/score?matchid=${encodeURIComponent(team.match_id)}`}
                key={team.match_id}
              >
                <div className="item" key={index}>
                  <div className="fixturehome">
                    <div className="container-fluid">
                      <div className="row">
                        <div className="fixturecard">
                  
                          <h6 className="daymatch">
                            
                            DAY {team.day}, MATCH {team.match_day}

                        

                          </h6>
 

                          {team.status === 'Live' && (
  <p className="MatchLivefixture mx-auto"> LIVE</p>
)}
{team.status === 'Full-Time' && (
  <p className="MatchFullfixture mx-auto">FULL TIME</p>
)}
{team.status === null && (  
<><p className="MatchUpfixture mx-auto">UPCOMING </p></> 
)}
{team.status === "None" && (  
<><p className="MatchUpfixture mx-auto">UPCOMING </p></> 
)}

                          <p>
                            {date}, <b>{day.toUpperCase()}</b> <br />
                            Noida Indoor Stadium
                            <br />{" "}
                            <b className="boldnew">Time : {team.match_time}</b>
                          </p>
                          <div className="row">
                            <div className="col-4">
                              <div className="fixtureleft">
                                <img
                                  src={`https://dev-upkl.upkl.in/${team.team_a_logo}`}
                                  className="img-fluid"
                                  alt="Logo"
                                />
                              </div>
                            </div>
                            <div className="col-4 align-self-center">
                              <div className="fixturecenter">
                                <div className="scorebox">

                                  <h5> {team.team_a_score ? ` ${team.team_a_score} - ${team.team_b_score}` : 'VS'}</h5>
                                </div>
                              </div>
                            </div>
                            <div className="col-4">
                              <div className="fixtureleft">
                                <img
                                  src={`https://dev-upkl.upkl.in/${team.team_b_logo}`}
                                  className="img-fluid"
                                  alt="Logo"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-5 p-0">
                              <div className="fixtureleft">
                                <h3>{team.team_a_name}</h3>
                              </div>
                            </div>
                            <div className="col-2">
                              <div className="fixturecenter">
                                <h3></h3>
                              </div>
                            </div>
                            <div className="col-5">
                              <div className="fixtureleft">
                                <h3>{team.team_b_name}</h3>
                              </div>
                            </div>
                          </div>
                          {/* <button className='moreinfo'>MATCH INFO</button> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                </Link>
              );
            })
          ) : (
            <SkeletonTheme baseColor="#292679" highlightColor="#39358b70">
              <div className="image-container">
                <div className="overlay"></div>
                <Skeleton width={260} height={400} />{" "}
                {/* Adjust width and height as per your design */}
              </div>
            </SkeletonTheme>
          )}
        </OwlCarousel>
        )}
      </section>
    </>
  );
}




 



