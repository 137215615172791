import React, { useState, useEffect } from "react";
import Nav from "../Include/Nav";
import arrow from "../arrow.svg";
import { Helmet } from "react-helmet";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Link } from "react-router-dom";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import Footer from "../Include/Footer1";
const breadcumb = "https://dev-upkl.upkl.in/files/breadcumb1.png";

export default function New() {
  const [standings, setStandings] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchStandings = async () => {
      try {
        const response = await fetch(
          'https://dev-upkl.upkl.in/api/resource/News%20and%20Updates?fields=["*"]'
        );
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const data = await response.json();
        console.log("Fetched data:", data); // Log the fetched data
        setStandings(data.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchStandings();
  }, []);

  const options3 = {
    loop: true,
    margin: 20,
    nav: false,
    dots: false,
    autoplay: true,
    autoplayTimeout: 4200,
    responsive: {
      0: {
        items: 1.6,
      },
      600: {
        items: 3,
      },
      1000: {
        items: 3.9,
      },
    },
  };

  return (
    <>
      <section className="tvsection">
        <div className="titlecaption">
          <div className="d-flex">
            <div className="newarrows">
              <h2 className="text-white">LATEST NEWS</h2>
              <div className="linetilte mb-2"></div>
            </div>
            <div className="abc">
              <img src={arrow} className="textrightarrow" alt="UPKL news" />
            </div>
          </div>
        </div>
        {loading ? (
          <SkeletonTheme baseColor="#292679" highlightColor="#39358b70">
            <Skeleton width={260} height={400} />{" "}
          </SkeletonTheme>
        ) : (
          <OwlCarousel className="owl-theme" {...options3}>
          {standings.length > 0 ? (
            standings.map((team, index) => (
              <Link target="_blank" to={team.news_links}>
                <div className="card">
                  <img
                    className="card-img-top"
                    src={`https://dev-upkl.upkl.in/${team.image}`}
                    alt="Card image cap"
                  />
                  <div className="card-body">
                    <img src={arrow} className="img-fluid newarrow1" />

                    <p className="card-text">
                      {team.channel} &nbsp;
                      <span className="text-white datetv">| 3 June 2024</span>
                    </p>

                    <h5 className="card-title">
                      {" "}
                      {team.title.split(" ").slice(0, 13).join(" ")}{" "}
                      {/* Extract first 30 words */}
                      {team.title.split(" ").length > 30 ? "..." : ""}{" "}
                    </h5>
                  </div>
                </div>
              </Link>
            ))
          ) : (
            <SkeletonTheme baseColor="#292679" highlightColor="#39358b70">
              <div className="image-container">
                <div className="overlay"></div>
                <Skeleton width={240} height={300} />{" "}
                {/* Adjust width and height as per your design */}
              </div>
            </SkeletonTheme>
          )}
        </OwlCarousel>
        )}
      </section>
    </>
  );
}




 




