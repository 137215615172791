import React, { useState, useEffect } from "react";
import Footer from "../Include/Footer1";
import Nav from "../Include/Nav";
import teamlogo from "../footerlogo.svg";
import arrow from "../matcharrow.svg";
import arrow1 from "../arrow.svg";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
function Matches() {
  const breadcumb = "https://dev-upkl.upkl.in//files/breadcumb1.png";
  const [matches, setMatches] = useState([]);
  const [loading, setLoading] = useState(true);
  const now = new Date();
  const currentDay = now.getDate();
  const currentMonth = now.getMonth() + 1; // getMonth is zero-indexed
  const currentYear = now.getFullYear();


  useEffect(() => {
    const fetchMatches = async () => {
      try {


        const currentDate = `${currentYear}-${currentMonth}-${currentDay}`;

        const response = await fetch(`https://dev-upkl.upkl.in/api/resource/FIXTURES?filters=[["status", "=", "Full-Time"]]&limit_page_length=100&fields=["*"]&order_by=match_number DESC`

        );
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const data = await response.json();

        // Sort by position field
        const sortedData = data.data.sort(
          (a, b) => b.match_number - a.match_number 
        );

        setMatches(sortedData);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchMatches();
  }, []);

  // Group matches by match_date
  const groupMatchesByDate = (matches) => {
    return matches.reduce((acc, match) => {
      const matchDate = new Date(match.match_date).toDateString();
      if (!acc[matchDate]) {
        acc[matchDate] = [];
      }
      acc[matchDate].push(match);
      return acc;
    }, {});
  };

  const groupedMatches = groupMatchesByDate(matches);

  return (
    <>
      <Helmet>
        <title> Upcoming Kabaddi Matches Schedule | UPKL Matches at Noida Indoor Stadium</title>
        <meta
          name="title"
          content="Upcoming Kabaddi Matches Schedule | UPKL Matches at Noida Indoor Stadium"
        />
         <meta
          name="keyword"
          content="upcoming kabaddi matches schedule UPKL matches Noida indoor stadium"
        />
        <meta
          name="description"
          content="Stay updated with the latest upcoming Kabaddi matches schedule at the Noida Indoor Stadium. Explore all the thrilling UPKL matches, dates, and venues. Don't miss a moment of the action"
        />
        <link rel="canonical" href="http://upkl.in/matches" />
      </Helmet>
      <Nav />

      
      <section className="breadcrumb">
        <img src={breadcumb} className="img-fluid" alt="UPKL Matches" />
        <div className="breadcrumb-caption">
          <div className="d-flex">
            <div className="newarrows">
              <h2 className="text-white">MATCHES</h2>
              <div className="linetilte"></div>
            </div>
            <div className="abc">
              <img src={arrow1} className="textrightarrow" alt="UPKL Matches" />
            </div>
          </div>
        </div>
      </section>
  
      <section>
        <div className="matchstrip">
          <div className="container-fluid">
            {loading ? (
              <p className="text-white">Loading...</p>
            ) : (
              Object.keys(groupedMatches).map((date) => (
                

                <div key={date}>
                    <div className="d-flex">
                  <div className="titlecaption">
                    <h2 className="matchhead">{date} </h2> 

                    
                    <div className="linetilte"></div>
                    

                  </div>
                  </div>
                  {groupedMatches[date].map((match, index) => (
                   <Link  to={`/score?matchid=${encodeURIComponent(match.match_id)}`}> <div className="matchesbox" key={match.name}>
                      <div className="row">
                        <div className="col-md-1 col-12 align-self-center">
                          <div className="matchleft">
                            <h5 className="finalmatch">
                              MATCH {match.match_day}
                            </h5>
                          </div>
                          <div className="matchseparator desktopnone"></div>
                        </div>
                        <div className="col-md-8 col-12">
                          <div className="matchcenter">
                            <div className="row">
                              <div className="col-md-2 col-2 align-self-center">
                                <img
                                  src={`https://dev-upkl.upkl.in/${match.team_a_logo}`}
                                  className="float-right img-fluid"
                                  alt={match.team_a_name}
                                />
                              </div>
                              <div className="col-3 align-self-center">
                                <div className="teamname">
                                  <h6>{match.team_a_name}</h6>
                                </div>
                              </div>
                              <div className="col-md-2 col-2 p-0 p-sm-0 align-self-center">
                                <div className="match-score-tl">
                                {match.status === 'Live' && (
  <p className="MatchLive"> <span className="dotslive"></span><span className="livetxt">LIVE</span></p>
)}
{match.status === 'Full-Time' && (
  <p className="fulltime">Full Time</p>
)}
{match.status === null && (
<></> 
)}




                                </div>
                                <div className="match-score">
                                <h5> {match.team_a_score ? ` ${match.team_a_score}-${match.team_b_score}` : 'VS'}</h5>
                                </div>
                              </div>
                              <div className="col-3 align-self-center">
                                <div className="teamname">
                                  <h6>{match.team_b_name}</h6>
                                </div>
                              </div>
                              <div className="col-md-2 col-2 align-self-center">
                                <img
                                  src={`https://dev-upkl.upkl.in/${match.team_b_logo}`}
                                  className="float-left img-fluid"
                                  alt={match.team_b_name}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-3 col-12 align-self-center">
                          <div className="row">
                            <div className="col-md-8 col-12 p-0">
                              <div className="matchright">
                                <p>
                                  Noida Indoor Stadium, Noida <br></br>
                                  <b>(Time : {match.match_time} )</b>
                                </p>
                              </div>
                            </div>
                            <div className="col-4 d-none d-md-block d-lg-block">
                              <center>
                                <div className="matchlogotop">
                                  <div className="matchlogo">
                                    <img src={teamlogo} alt="Match Logo" />
                                  </div>
                                </div>
                              </center>
                              <img
                                src={arrow}
                                className="img-fluid matcharrow"
                                alt="Arrow"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    </Link>
                  ))}
                </div>
                
              ))
            )}
          </div>
        </div>
      </section>
      <Footer/>
    </>
  );
}
export default Matches;
