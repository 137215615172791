import React, { useState, useEffect } from "react";
import arrow from "../arrow.svg";
import { Link } from "react-router-dom";

export default function Banner() {
  const [standings, setStandings] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchStandings = async () => {
      try {
        const response = await fetch(
          'https://dev-upkl.upkl.in/api/resource/Banners?fields=["*"]'
        );
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const data = await response.json();
        setStandings(data.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchStandings();
  }, []);

  return (
    <>
      <div
        id="carouselExampleIndicators"
        className="carousel slide dshow"
        data-ride="carousel"
        data-interval="5000"
      >
        <ol className="carousel-indicators">
          {standings.map((team, index) => (
            <li
              key={index}
              data-target="#carouselExampleIndicators"
              data-slide-to={index}
              className={index === 0 ? "active" : ""}
            ></li>
          ))}
        </ol>
        <div className="carousel-inner">
          {standings.map((team, index) => (
            <div
              key={index}
              className={`carousel-item ${index === 0 ? "active" : ""}`}
            >
              <Link to={team.page_url}>
                <div className="banner-container">
                  <div className="banner-overlay"></div>
                  <img
                    src={`https://dev-upkl.upkl.in/${team.banner_image_desktop}`}
                    alt="Banner"
                    className="banner-image"
                  />
                  <div className="banner-caption">
                    <h2>
                      {team.title}
                      <img src={arrow} className="arrow img-fluid" />
                    </h2>
                    <span className="line"></span>
                    <p></p>
                  </div>
                </div>
              </Link>
            </div>
          ))}
        </div>
        <a
          className="carousel-control-prev"
          href="#carouselExampleIndicators"
          role="button"
          data-slide="prev"
        >
          <span
            className="carousel-control-prev-icon"
            aria-hidden="true"
          ></span>
          <span className="sr-only"></span>
        </a>
        <a
          className="carousel-control-next"
          href="#carouselExampleIndicators"
          role="button"
          data-slide="next"
        >
          <span
            className="carousel-control-next-icon"
            aria-hidden="true"
          ></span>
          <span className="sr-only"></span>
        </a>
      </div>




       <div
        id="carouselExampleIndicators"
        className="carousel slide mshow"
        data-ride="carousel"
        data-interval="5000"
      >
        <ol className="carousel-indicators">
          {standings.map((team, index) => (
            <li
              key={index}
              data-target="#carouselExampleIndicators"
              data-slide-to={index}
              className={index === 0 ? "active" : ""}
            ></li>
          ))}
        </ol>
        <div className="carousel-inner">
          {standings.map((team, index) => (
            <div
              key={index}
              className={`carousel-item ${index === 0 ? "active" : ""}`}
            >
              <Link to={team.page_url}>
                <div className="banner-container">
                  <div className="banner-overlay"></div>
                  <img
                    src={`https://dev-upkl.upkl.in/${team.mobile_image}`}
                    alt="Banner"
                    className="banner-image"
                  />
                  <div className="banner-caption">
                    <h2>
                      {team.title}
                      <img src={arrow} className="arrow img-fluid" />
                    </h2>
                    <span className="line"></span>
                    {/* <p>June 03, 2024</p> */}
                  </div>
                </div>
              </Link>
            </div>
          ))}
        </div>
        <a
          className="carousel-control-prev"
          href="#carouselExampleIndicators"
          role="button"
          data-slide="prev"
        >
          <span
            className="carousel-control-prev-icon"
            aria-hidden="true"
          ></span>
          <span className="sr-only"></span>
        </a>
        <a
          className="carousel-control-next"
          href="#carouselExampleIndicators"
          role="button"
          data-slide="next"
        >
          <span
            className="carousel-control-next-icon"
            aria-hidden="true"
          ></span>
          <span className="sr-only"></span>
        </a>
      </div> 
    </>
  );
}
