import React, { useState, useEffect } from "react";
import arrow1 from "../arrow.svg";
import Nav from "../Include/Nav";
import arrownlblog from "../arrownlblog.svg";
import { Link } from "react-router-dom";
import Footer from "../Include/Footer1";
import border from "../borderblog.svg";
import { Helmet } from "react-helmet";

export default function Photo() {
  const [standings, setStandings] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const fetchStandings = async () => {
      try {
        const response = await fetch(
          'https://dev-upkl.upkl.in/api/resource/UPKL%20Gallery?fields=["*"]'
        );
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const data = await response.json();
        const sortedData = data.data.sort((a, b) => a.match_day - b.match_day);

        setStandings(sortedData);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchStandings();
  }, []);

  const breadcumb = "https://dev-upkl.upkl.in//files/breadcumb1.png";

  return (
    <>
      <Helmet>
        <title>
          Uttar Pradesh Kabaddi Updates |Gallery 
        </title>
        <meta
          name="title"
          content="Uttar Pradesh Kabaddi Updates"
        />
        <meta
          name="keywords"
          content="UPKL Gallery"
        />
        <meta
          name="description"
          content="Get the latest updates on Uttar Pradesh Kabaddi, including UPKL announcements and details on the UPKL Mega Auction 2024. Stay informed with the newest blog posts and insights."
        />

        <link rel="canonical" href="https://upkl.in/blog-list" />
      </Helmet>

      <Nav />
      <section className="breadcrumb">
        <img src={breadcumb} className="img-fluid" alt="UPKL BLOG" />
        <div className="breadcrumb-caption">
          <div className="d-flex">
            <div className="newarrows">
              <h2 className="text-white">UPKL GALLERY</h2>
              <div className="linetilte"></div>
            </div>
            <div className="abc">
              <img src={arrow1} className="textrightarrow" alt="UPKL BLOG" />
            </div>{" "}
          </div>
        </div>
      </section>

      <section className="galleryupkl">
        <div className="container-fluid">
          <div className="row row1">
            {standings.length > 0 ? (
              standings.map((team, index) => (
                <div className="col-md-4 col-12">
                  <Link to={`/Photodetails?id=${team.name}`}>
                    <div className="card">
                      <img src={border} className="img-fluid bordernew" />
                      <img
                        className="card-img-top"
                        src={`https://dev-upkl.upkl.in/${team.thumbnail_banner}`}
                        alt="Card image cap"
                      />
                      <div className="card-body">
                        <p className="card-text">
                          UPKL&nbsp;
                          <span className="text-white datetv">
                          | {team.date}
                          </span>
                        </p>

                        <h5 className="card-title">
                          {team.tittle.split(" ").slice(0, 25).join(" ")}{" "}
                          {/* Extract first 30 words */}
                          {team.tittle.split(" ").length > 30 ? "" : ""}{" "}
                          {/* Add ellipsis if title exceeds 30 words */}
                          ..
                        </h5>

                      </div>
                    </div>
                  </Link>
                </div>
              ))
            ) : (
              <p>Loading..</p>
            )}
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}
