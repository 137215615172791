import React from "react";
import Footer from "../Include/Footer1";
import Nav from "../Include/Nav";
import arrow1 from "../arrow.svg";
import logo11 from "./Noida ninjas.png";
import logo12 from "./Kashi Kings (1).png";
import logo13 from "./lucknow (1).png";
import logo14 from "./yamuna yodhas.png";
import logo15 from "./ganga mirzapur.png";
import logo16 from "./sangam chailengers (1).png";
import logo17 from "./Brij Stars.png";
import logo18 from "./awadh (1).png";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

function Team() {
  const breadcumb = "https://dev-upkl.upkl.in//files/breadcumb1.png";
  return (
    <>
      <Helmet>
        <title>
          UPKL Teams | UPKL Team Details | Uttar Pradesh Kabaddi Teams
        </title>
        <meta
          name="title"
          content="UPKL Teams | UPKL Team Details | Uttar Pradesh Kabaddi Teams"
        />
        <meta
          name="keywords"
          content="UP Kabaddi League teams UPKL team details uttar pradesh kabaddi teams"
        />
        <meta
          name="description"
          content="Explore detailed information about UPKL teams. Learn about team rosters, player profiles, and updates on Uttar Pradesh kabaddi teams in the league"
        />
        <link rel="canonical" href="https://upkl.in/team" />
      </Helmet>
      <Nav />
      <section className="breadcrumb">
        <img src={breadcumb} className="img-fluid" alt="UPKL TEAM" />
        <div className="breadcrumb-caption">
          <div className="d-flex">
            <div className="newarrows">
              <h2 className="text-white">UPKL TEAM</h2>
              <div className="linetilte"></div>
            </div>
            <div className="abc">
              <img
                src={arrow1}
                className="textrightarrow"
                alt="UP Kabaddi League teams
UPKL team details
uttar pradesh kabaddi teams"
              />
            </div>{" "}
          </div>
        </div>
      </section>

      <section className="teamsection">
        <div className="container">
          <div className="row">
            <div className="col-md-3 col-12">
              <div className="teamcard">
                <div className="topsection topsection1">
                  <img
                    src="https://dev-upkl.upkl.in//files/Rectangle%2054161048.svg"
                    className="img-fluid"
                    alt="UP Kabaddi League teams
UPKL team details
uttar pradesh kabaddi teams"
                  />
                  <center>
                    <div className="overlayplayer">JD NOIDA NINJAS</div>
                  </center>
                </div>
                <div className="bottomplaysection">
                  <img
                    src={logo11}
                    className="img-fluid"
                    alt="UP Kabaddi League teams
UPKL team details
uttar pradesh kabaddi teams"
                  />
                  <div className="row">
                    <div className="d-flex btnteams">
                    <Link to="/players?id=Noida Ninjas"> <button>VIEW PROFILE</button></Link>
                      <Link to="/teammatch?team=NOIDA NINJAS">
                      <button>VIEW MATCHES</button>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-3 col-12">
              <div className="teamcard">
                <div className="topsection topsection1">
                  <img
                    src="https://dev-upkl.upkl.in//files/Rectangle%2054.svg"
                    className="img-fluid"
                    alt="UP Kabaddi League teams
UPKL team details
uttar pradesh kabaddi teams"
                  />
                  <center>
                    <div className="overlayplayer">KASHI KINGS</div>
                  </center>
                </div>
                <div className="bottomplaysection">
                  <img
                    src={logo12}
                    className="img-fluid"
                    alt="UP Kabaddi League teams
UPKL team details
uttar pradesh kabaddi teams"
                  />
                  <div className="row">
                    <div className="d-flex btnteams">
                    <Link to="/players?id=Kashi Kings"> <button>VIEW PROFILE</button></Link>
                    <Link to="/teammatch?team=KASHI KINGS"> <button>VIEW MATCHES</button></Link>
                     
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-3 col-12">
              <div className="teamcard">
                <div className="topsection topsection1">
                  <img
                    src="https://dev-upkl.upkl.in//files/Rectangle%2054138460.svg"
                    className="img-fluid"
                    alt="UP Kabaddi League teams
UPKL team details
uttar pradesh kabaddi teams"
                  />
                  <center>
                    <div className="overlayplayer">LUCKNOW LIONS</div>
                  </center>
                </div>
                <div className="bottomplaysection">
                  <img
                    src={logo13}
                    className="img-fluid"
                    alt="UP Kabaddi League teams
UPKL team details
uttar pradesh kabaddi teams"
                  />
                  <div className="row">
                    <div className="d-flex btnteams">
                    <Link to="/players?id=Lucknow Lions"> <button>VIEW PROFILE</button></Link>
                      <Link to="/teammatch?team=LUCKNOW LIONS"> <button>VIEW MATCHES</button></Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-3 col-12">
              <div className="teamcard">
                <div className="topsection topsection1">
                  <img
                    src="https://dev-upkl.upkl.in//files/Rectangle%20542c2933.svg"
                    className="img-fluid"
                    alt="UP Kabaddi League teams
UPKL team details
uttar pradesh kabaddi teams"
                  />
                  <center>
                    <div className="overlayplayer">YAMUNA YODHAS</div>
                  </center>
                </div>
                <div className="bottomplaysection">
                  <img
                    src={logo14}
                    className="img-fluid"
                    alt="UP Kabaddi League teams
UPKL team details
uttar pradesh kabaddi teams"
                  />
                  <div className="row">
                    <div className="d-flex btnteams">
                    <Link to="/players?id=Yamuna Yodhas"> <button>VIEW PROFILE</button></Link>
                      <Link to="/teammatch?team=YAMUNA YODHAS"> <button>VIEW MATCHES</button></Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-3 col-12">
              <div className="teamcard">
                <div className="topsection topsection1">
                  <img
                    src="https://dev-upkl.upkl.in//files/Rectangle%20548c1936.svg"
                    className="img-fluid"
                    alt="UP Kabaddi League teams
UPKL team details
uttar pradesh kabaddi teams"
                  />
                  <center>
                    <div className="overlayplayer">KINGS OF MIRZAPUR</div>
                  </center>
                </div>
                <div className="bottomplaysection">
                  <img
                    src={logo15}
                    className="img-fluid"
                    alt="UP Kabaddi League teams
UPKL team details
uttar pradesh kabaddi teams"
                  />
                  <div className="row">
                    <div className="d-flex btnteams">
                    <Link to="/players?id=Ganga King Of Mirzapur"> <button>VIEW PROFILE</button></Link>
                      <Link to="/teammatch?team=KINGS OF MIRZAPUR"> <button>VIEW MATCHES</button></Link>

                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-3 col-12">
              <div className="teamcard">
                <div className="topsection topsection1">
                  <img
                    src="https://dev-upkl.upkl.in//files/Rectangle%20540418cc.svg"
                    className="img-fluid"
                    alt="UP Kabaddi League teams
UPKL team details
uttar pradesh kabaddi teams"
                  />
                  <center>
                    <div className="overlayplayer">SANGAM CHALLENGERS</div>
                  </center>
                </div>
                <div className="bottomplaysection">
                  <img
                    src={logo16}
                    className="img-fluid"
                    alt="UP Kabaddi League teams
UPKL team details
uttar pradesh kabaddi teams"
                  />
                  <div className="row">
                    <div className="d-flex btnteams">
                    <Link to="/players?id=Sangam Challengers"> <button>VIEW PROFILE</button></Link>
                      <Link to="/teammatch?team=SANGAM CHALLENGERS"> <button>VIEW MATCHES</button></Link>

                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-3 col-12">
              <div className="teamcard">
                <div className="topsection topsection1">
                  <img
                    src="https://dev-upkl.upkl.in//files/Rectangle%2054a95df2.svg"
                    className="img-fluid"
                    alt="UP Kabaddi League teams
UPKL team details
uttar pradesh kabaddi teams"
                  />
                  <center>
                    <div className="overlayplayer">BRIJ STARS</div>
                  </center>
                </div>
                <div className="bottomplaysection">
                  <img
                    src={logo17}
                    className="img-fluid"
                    alt="UP Kabaddi League teams
UPKL team details
uttar pradesh kabaddi teams"
                  />
                  <div className="row">
                    <div className="d-flex btnteams">
                    <Link to="/players?id=Brij Stars"> <button>VIEW PROFILE</button></Link>
                      <Link to="/teammatch?team=BRIJ STARS"> <button>VIEW MATCHES</button></Link>

                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-3 col-12">
              <div className="teamcard">
                <div className="topsection topsection1">
                  <img
                    src="https://dev-upkl.upkl.in//files/Rectangle%20540cb131.svg"
                    className="img-fluid"
                    alt="UP Kabaddi League teams
UPKL team details
uttar pradesh kabaddi teams"
                  />
                  <center>
                    <div className="overlayplayer">AWADH RAMDOOTS</div>
                  </center>
                </div>
                <div className="bottomplaysection">
                  <img
                    src={logo18}
                    className="img-fluid"
                    alt="UP Kabaddi League teams
UPKL team details
uttar pradesh kabaddi teams"
                  />
                  <div className="row">
                    <div className="d-flex btnteams">
                    <Link to="/players?id=Awadh Ramdoots"> <button>VIEW PROFILE</button></Link>
                      <Link to="/teammatch?team=AWADH RAMDOOTS"> <button>VIEW MATCHES</button></Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}

export default Team;
