import React, { useState, useEffect } from "react";
import Nav from "../Include/Nav";
import Footer from "../Include/Footer1";
import arrow from "../arrow.svg";
import { Helmet } from "react-helmet";
import arrowyellow from './yellpwarrow.png'
export default function Playerdetails() {
  const [players, setPlayers] = useState([]);
  const [breadcumb, setBreadcumb] = useState("https://dev-upkl.upkl.in/files/breadcumb1.png");
 console.log(players,"helloe world")
  useEffect(() => {
    fetchPlayers();
  }, []);

  const fetchPlayers = async () => {
    try {
      
      const params = new URLSearchParams(window.location.search);
      const id = params.get("id");
      const response = await fetch(
        `https://dev-upkl.upkl.in/api/resource/UPKL%20Players/${id}`
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      setPlayers(data.data); // Assuming the API response has a structure where data is an array of player objects
    } catch (error) {
      console.error("Error fetching players:", error);
    }
  };

  return (
    <>
      <Helmet>
        <title>Uttar Pradesh Kabaddi League (UPKL) - Empowering Kabaddi Athletes.</title>
        <meta name="title" content="Uttar Pradesh Kabaddi League (UPKL) - Empowering Kabaddi Athletes." />
        <meta
          name="description"
          content="Learn about the Uttar Pradesh Kabaddi League (UPKL), a dedicated platform committed to nurturing local Kabaddi talents. Understand UPKL's mission to engage audiences and reinforce Kabaddi's significance in India."
        />
        <link rel="canonical" href="http://upkl.in/about" />
      </Helmet>

      <Nav />

    

      <section className="playerdetails">
  <div className="container-fluid">
    <div className="bg-yamuna">
      <div className="row">
        <div className="col-md-6 col-12 align-self-center">
          <div className="leftcardplayer">
            <div className="imgplayer">
            <img src={players.compressed_img ? `https://dev-upkl.upkl.in/${players.compressed_img}` : 'https://dev-upkl.upkl.in/files/VECTOR-WEBSITE.png-03.png'} alt="Player Image" />
            </div>
          </div>
        </div>
        <div className="col-md-6 col-12  align-self-center">
          <div className="rightblockplayer">
            <div className="row">
              <div className="col-12">
                <div className="stats">
                  <h4>{players.playername} &nbsp;</h4>
                 <div className="circle1"> <div class="circle">
       <p> Matches<br></br>{players.matches}</p>
    </div>
    </div>
                      <div className="playerline"></div>
                  <p>Position : {players.role || ''}</p>

                  <p>DOB : {players.dob || '-'} | Team  : {players.team || '-'}</p>
                  {/* <p>DOB: {players.dob || '-'} |  {players.team || '-'}</p> */}
                </div>
              </div>
            </div>
          </div>



        <div className="d-flex">
        <div class="box">
        <h2>{players.totalpoints}</h2>
        <p>Total Points</p>
    </div>

    <div class="box">
        <h2>{players.totaltouchpoints}</h2>
        <p>Touch Points</p>
    </div>


    <div class="box">
        <h2>{players.raidbonuspoints}</h2>
        <p>Bonus Points</p>
    </div>


    <div class="box">
    <h2>{players.totaltacklepoints || '-'}</h2>
    <p>Tackle Points</p>
    </div>
        </div>

        <br></br>
        <div className="row">
    <div className="col-md-6">
      <div className="headinpointer">
        <h6>Total Raids : {players.totalraids}
        </h6>
      </div>
      <div className="verticalbg">
        <ul>
          <li><div className="row">
            <div className="col-10"><p className="playerstaticpage">Super Raids</p>
            </div>
            <div className="col-2"><p className="playerstaticpage">{players.superraids}</p></div>
            </div></li>
            <li><div className="row">
            <div className="col-10"><p className="playerstaticpage"> Successful Raids</p>
            </div>
            <div className="col-2"><p className="playerstaticpage">{players.successfulraids}</p></div>
            </div></li>

            <li><div className="row">
            <div className="col-10"><p className="playerstaticpage"> Unsuccessful Raids</p>
            </div>
            <div className="col-2"><p className="playerstaticpage">{players.unsuccessfulraids}</p></div>
            </div></li>

            <li><div className="row">
            <div className="col-10"><p className="playerstaticpage"> SuperTen </p>
            </div>
            <div className="col-2"><p className="playerstaticpage">{players.superten}</p></div>
            </div></li>
        </ul>
      </div>
    </div>
    <div className="col-md-6">
    <div className="headinpointer">
        <h6>Total Tackles : {players.totaltackles}

        </h6>
      </div>
      <div className="verticalbg">
        <ul>
          <li><div className="row">
            <div className="col-10"><p className="playerstaticpage">Super Tackles
            </p>
            </div>
            <div className="col-2"><p className="playerstaticpage">{players.supertackles}</p></div>
            </div></li>
            <li><div className="row">
            <div className="col-10"><p className="playerstaticpage">Successful Tackles</p>
            </div>
            <div className="col-2"><p className="playerstaticpage">{players.successfultackles}</p></div>
            </div></li>

            <li><div className="row">
            <div className="col-10"><p className="playerstaticpage"> Unsuccessful Tackles</p>
            </div>
            <div className="col-2"><p className="playerstaticpage">{players.unsuccessfultackles}</p></div>
            </div></li>
            <li><div className="row">
            <div className="col-10"><p className="playerstaticpage"> HighFive</p>
            </div>
            <div className="col-2"><p className="playerstaticpage">{players.highfive}</p></div>
            </div></li>
        </ul>
      </div>
    </div>

   </div>

        
        </div>
      </div>
    </div>
  </div>
</section>


      <Footer />
    </>
  );
}
