import React, { useState, useEffect } from "react";
import Nav from "../Include/Nav";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Footer from "../Include/Footer1";
import { Link } from "react-router-dom";

import fb from "../fb.png";
import inst from "../instagram.png";
import you from "../you-tube.png";
import twitter from "../twitter.png";
import arrow from "../arrow.svg";
import New from "../Components/New";
export default function SingleBlog() {
  const [standings, setStandings] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchStandings = async () => {
      try {
        const params = new URLSearchParams(window.location.search);
        const id = params.get("name");
        const response = await fetch(
          `https://dev-upkl.upkl.in/api/resource/UPKL%20Blog/${id}?fields=["*"]`
        );
        console.log(response, "aas");
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const data = await response.json();
        setStandings(data.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };
    fetchStandings();
  },[]);

  return (
    <>
      <Nav />

      <div className="singleblog">
        <div className="row1">
          <div className="blogbanner">
            <img
              src={`https://dev-upkl.upkl.in/${standings.banner_image}`}
              className="img-fluid"
              alt="UPKL 2024"
            />
          </div>

          <div className="blog-details">
            <div className="col-md-8 col-12 offset-md-2">
              <div className="row">
                <div className="blogheadng">
                  <h2>
                    {standings.title}{" "}
                    <img src={arrow} className="arrow img-fluid" />
                  </h2>
                </div>

                <div className="blogline"></div>

                <div className="row row1">
                  <div className="col-md-6 col-6">
                    <p className="blogdate">{standings.blog_date} </p>
                  </div>
                  <div className="col-3 mobilenone"></div>
                  <div className="col-md-3 col-6 d-flex">
                    <p className="blogshareicon">Social</p>
                    <div className="footerrightmenu">
                      <div className="bloglogo">
                        <ul className="d-flex pl-0">
                          <li className="">
                           <Link target="_blank"  to={`https://www.facebook.com/sharer/sharer.php${standings.title}`}> <img src={fb} /></Link>
                          </li>
                          {/* <li>

                            <Link target="_blank" src={`http://www.linkedin.com/shareArticle?mini=true&url=${standings.title}`}>
                            <img src={inst} />
                            </Link>
                          </li> */}
                          {/* <li>
                            <img src={you} />
                          </li> */}
                            <Link target="_blank" to={`http://twitter.com/share?text=${standings.title}`}>
                            <img src={twitter} />
                            </Link>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="blogpara p-0">
                  <p
                    dangerouslySetInnerHTML={{
                      __html: standings.blog_description,
                    }}
                  ></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <New />

      <Footer />
    </>
  );
}
