import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import l1 from  './Kashi/kashi-01.jpg'
import l2 from  './Kashi/kashi-02.jpg'
import l3 from  './Kashi/kashi-03.jpg'
import l4 from  './Kashi/kashi-04.jpg'
import img10 from './Kashi/11.jpg'
import img11 from './Kashi/12.jpg'
import img12 from './Kashi/13.jpg'

import arrow from "../arrow.svg";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import arrow1 from "../arrow.svg";
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Footer from '../Include/Footer1';
import Nav from '../Include/Nav';
import Banner from '../Components/Banner';
const breadcumb = "https://dev-upkl.upkl.in/files/breadcumb1.png";

const Kashi = () => {
  const [activeCity, setActiveCity] = useState('kashi');


  const options = {
    loop: true,
    margin:10,
    nav: false,
    // autoplay: true, // Enable autoplay
    // autoplayTimeout: 3000, // Autoplay interval in milliseconds (3 seconds)
    responsive: {
        0: {
            items: 1.4,
            margin: 5,
        },
        600: {
            items: 3
        },
        1000: {
            items: 4
        }
    }
};


  const renderContent = () => {
    switch (activeCity) {
      case 'kashi':
        return (
          <>
            <div className="col-md-4 col-12">
              <div className="trophy-content">
                <h5>Kashi Kings Bring Tradition and Sportsmanship to Varanasi
                </h5>
                <p>
                The spiritual capital of India, Varanasi, also known as Kashi, welcomed the UPKL Trophy Tour with a unique blend of tradition and sportsmanship. The Kashi Kings and their supporters lined the ghats of the Ganges, where the trophy was blessed by priests in a ceremonial ritual.
                The evening concluded with a mesmerising Ganga Aarti, reflecting the city's spiritual and cultural richness. This stop on the tour highlighted the deep connection between Varanasi's heritage and its love for kabaddi, creating an unforgettable experience for all involved.

                </p>
              </div>
            </div>
            <div className="col-4"></div>
            <div className="col-md-4 col-12">
              <div className="trophy-content2">
                <h5>Spiritual Grandeur
                </h5>
                <p>
                The Kashi Kings and their supporters lined the ghats of the Ganges attending the ganga aarti in unison. The evening concluded with a mesmerising Ganga Aarti, reflecting the city's spiritual and cultural richness.
                </p>
              </div>
            </div>
          </>
        );
      case 'ayodhya':
        return (
          <>
            <div className="col-md-4 col-12">
              <div className="trophy-content">
                <h5>Spiritual Heritage Meets Sports in Ayodhya with the Awadh Ramdoots</h5>
                <p>
                  Ayodhya, renowned for its deep spiritual heritage, hosted the UPKL Trophy Tour with an awe-inspiring reception. The Awadh Ramdoots, representing the city, put together a grand spectacle. The trophy was witnessed and celebrated through the city with traditional music and dance, culminating in a ceremony at the Ram Janmabhoomi. This event symbolized Ayodhya's historic and cultural significance, blending spirituality with sportsmanship. The celebration showcased Ayodhya's devotion to both its spiritual roots and its emerging sports culture, making it a memorable stop on the tour. Locals of Ayodhya interacted with our team anchor Ujjwal Saraf sharing their point of view about the upcoming league and gave their best wishes to Awadh Ramdoots.
                </p>
              </div>
            </div>
            <div className="col-4"></div>
            <div className="col-md-4 col-12">
              <div className="trophy-content2">
                <h5>Journey Begins!</h5>
                <p>
                  The UPKL Trophy Tour was not just about displaying the gleaming silverware; it was a celebration of the sport and its deep-rooted connection with the people of Uttar Pradesh. As the trophy traversed through each city, it was met with immense enthusiasm, colourful festivities, and an unwavering love for kabaddi.
                </p>
              </div>
            </div>
          </>
        );
      // Add more cases for other cities...
      default:
        return null;
    }
  };

  return (
    <>
<Nav/>

<section className="breadcrumb">
        <img src={breadcumb} className="img-fluid" alt="Breadcrumb Image" />
        <div className="breadcrumb-caption">
          <div className="d-flex">
            <div className="newarrows">
              <h2 className="text-white">TROPHY  TOUR</h2>
              <div className="linetilte"></div>
            </div>
            <div className="abc">
              <img src={arrow1} className="textrightarrow" alt="Arrow" />
            </div>
          </div>
        </div>
      </section>

      <section className="stripcity">
        <div className="trophy-city">
          <ul className="d-flex">
             <li className={activeCity === 'lucknow' ? 'touractive' : ''}><Link to="/lucknow" onClick={() => setActiveCity('lucknow')}>Lucknow</Link></li>
            <li className={activeCity === 'ayodhya' ? 'touractive' : ''}><Link to="/ayodhya-tour" onClick={() => setActiveCity('ayodhya')}>AYODHYA</Link></li>
            <li className={activeCity === 'kashi' ? 'touractive' : ''}><Link to="/kashi-tour" onClick={() => setActiveCity('kashi')}>Kashi</Link></li>
            <li className={activeCity === 'mirzapur' ? 'touractive' : ''}><Link to="/mirzapur-tour" onClick={() => setActiveCity('mirzapur')}>MIRZAPUR</Link></li>
            <li className={activeCity === 'prayagraj' ? 'touractive' : ''}><Link to="/prayagraj-tour" onClick={() => setActiveCity('prayagraj')}>PRAYAGRAJ</Link></li>
            <li className={activeCity === 'kanpur' ? 'touractive' : ''}><Link to="/kanpur-tour" onClick={() => setActiveCity('kanpur')}>KANPUR</Link></li>
            <li className={activeCity === 'jashi' ? 'touractive' : ''}><Link to="/jashi-tour" onClick={() => setActiveCity('jashi')}>Jhansi
</Link></li>
            <li className={activeCity === 'mathura' ? 'touractive' : ''}><Link to="/mathura-tour" onClick={() => setActiveCity('noida')}>Mathura</Link></li>

            <li className={activeCity === 'agra' ? 'touractive' : ''}><Link to="/agra-tour" onClick={() => setActiveCity('noida')}>Agra</Link></li>
            <li className={activeCity === 'noida' ? 'touractive' : ''}><Link to="/noida-tour" onClick={() => setActiveCity('noida')}>NOIDA</Link></li>
          </ul>
        </div>
      </section>
      <section className="trophysection">
        <div className="trophysection1">
          <div className="container-fluid">
            <div className="row">
              {renderContent()}
            </div>
          </div>
        </div>
      </section>

      <section className='fixturessection1'>
      <div className='titlecaption'>
                    <div className='d-flex'>
                        <div className='newarrows'>
                            <h2 className='text-white'> KASHI  GALLERY</h2>
                            <div className='linetilte'></div>
                        </div>
                        <div className='abc'>
                            <img src={arrow} className='textrightarrow' alt="arrow" />
                        </div>
                    </div>
                </div>

        <div className='container-fluid'>
          
        <OwlCarousel className="owl-theme" {...options}>
          <div className='itemluck'>
            <img src={l1} className='img-fluid'/>
          </div>

          <div className='itemluck'>
            <img src={l2} className='img-fluid'/>
          </div>


          <div className='itemluck'>
            <img src={img11} className='img-fluid'/>
          </div>

          <div className='itemluck'>
            <img src={l3} className='img-fluid'/>
          </div>

          <div className='itemluck'>
            <img src={l4} className='img-fluid'/>
          </div>


          <div className='itemluck'>
            <img src={img10} className='img-fluid'/>
          </div>





          <div className='itemluck'>
            <img src={img12} className='img-fluid'/>
          </div>


          </OwlCarousel>
          
        </div>
      </section>


      <section className='fixturessection1'>
      <div className='titlecaption'>
                    <div className='d-flex'>
                        <div className='newarrows'>
                            <h2 className='text-white'> VIDEO</h2>
                            <div className='linetilte'></div>
                        </div>
                        <div className='abc'>
                            <img src={arrow} className='textrightarrow' alt="arrow" />
                        </div>
                    </div>
                </div>

        <div className='container-fluid'>
          
        <iframe width="100%" height="315" src="https://www.youtube.com/embed/xtDeDect-XQ?si=BstEBlQ2EbzWEBlH" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>        </div>
      </section>



      <Footer/>
    </>
  );
};

export default Kashi;
