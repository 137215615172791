import React, { useState, useEffect } from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import border from "../border.svg";
// import arrow from "../matcharrow.svg";
import arrow1 from "../matcharrow.svg";
import arrow from "../arrow.svg";
import img1 from '../Podcast Back Drop p1 .jpg';
import img2 from '../Podcast Back Drop p2.jpg'

import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import Nav from "../Include/Nav";
import Footer from "../Include/Footer1";
import { Link } from "react-router-dom";

export default function Tv() {
  const breadcumb = "https://dev-upkl.upkl.in//files/breadcumb1.png";

  const [standings, setStandings] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchStandings = async () => {
      try {
        const response = await fetch(
          'https://dev-upkl.upkl.in/api/resource/UPKL%20TV?fields=["*"]'
        );
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const data = await response.json();
        setStandings(data.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchStandings();
  }, []);

  const options3 = {
    loop: true,
    margin: 20,
    nav: false,
    loop: true,
    dots: false,
    autoplay: true, // Enable autoplay
    autoplayTimeout: 4200,
    responsive: {
      0: {
        items: 1.6,
      },
      600: {
        items: 3,
      },
      1000: {
        items: 4,
      },
    },
  };

  return (
    <>
    <Nav/>


    <section className="breadcrumb">
        <img src={breadcumb} className="img-fluid" alt="UPKL Matches" />
        <div className="breadcrumb-caption">
          <div className="d-flex">
            <div className="newarrows">
              <h2 className="text-white">UPKL TV</h2>
              <div className="linetilte"></div>
            </div>
            <div className="abc">
              <img src={arrow} className="textrightarrow" alt="UPKL Matches" />
            </div>
          </div>
        </div>
      </section>


      <div className="playerstats newpage">
        <div className="titlecaption">
          <div className="d-flex">
            <div className="newarrows">
              <h2 className="text-white">UPKL TV</h2>
              <div className="linetilte"></div>
            </div>
            <div className="abc">
              <img src={arrow} className="textrightarrow" alt="UPKL 2024" />
            </div>
          </div>
        </div>
        <div className="row">
              <div className="col-md-3 col-12">
             <Link to="https://www.youtube.com/watch?v=rXUbkY70PWg&t=61s" target="_blank"> 
             <div className="image-container">
                  <div className="overlay">
                    <img src={border} className="img-fluid bordernew"  alt="UPKL 2024" />
                    <div className="text">
                      <h5>UPKL | Season Draft Part 1</h5>
                      <div className="divider"></div>
                      <p>July 3, 2024</p>
                    </div>
                  </div>
                  <img
                    src={img1}
                    className="image"
                  />
                </div></Link>
              </div>


              <div className="col-md-3 col-12">
           <Link to="https://www.youtube.com/watch?v=yJb075-4MsU&t=3s"  target="_blank">
           <div className="image-container">
                  <div className="overlay">
                    <img src={border} className="img-fluid bordernew"  alt="UPKL 2024" />
                    <div className="text">
                      <h5>UPKL | Season Draft Part 2</h5>
                      <div className="divider"></div>
                      <p>July 3, 2024</p>
                    </div>
                  </div>
                  <img
                    src={img2}
                    className="image"
                  />
                </div></Link>
              </div>
           
        </div>
      </div>

      <Footer/>
    </>
  );
}
