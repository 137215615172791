
import React, { useState, useEffect, useRef  } from "react";
import "../App.css";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import team from "../team.svg";
import Nav from "../Include/Nav";
import arrow from "../arrow.svg";
import Footer from "../Include/Footer1";
import borderimg from "../boxborder.svg";
import "react-loading-skeleton/dist/skeleton.css";
import StandingsTable from "./StandingsTable";
import New from "./New";
import Banner from "./Banner";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import Fixture from "./Fixture";
import Auctionimg from "../Auction/27-june-auction-thumbnail (1).jpg";
import video from './video1.mp4'
import showimg from './show-more.png'
import modal from './Match day 9 .jpg';
import GalleryPlayer from "./Gallery";
const Home = () => {

  const [showModal, setShowModal] = useState(false);
  const modalRef = useRef(null);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowModal(true);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    let hideTimer;
    if (showModal) {
      hideTimer = setTimeout(() => {
        setShowModal(false);
      }, 6000);
    }
    return () => clearTimeout(hideTimer);
  }, [showModal]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setShowModal(false);
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return (
    <>
      <Helmet>
        <title>
          Uttar Pradesh Kabaddi League (UPKL) - Revitalizing Kabaddi in India.
        </title>
        <meta
          name="title"
          content="Uttar Pradesh Kabaddi League (UPKL) - Revitalizing Kabaddi in India."
        />
        <meta
          name="keywords"
          content="Uttar Pradesh Kabaddi League (UPKL) Kabaddi in India UPKL MEGA  AUCTION 2024"
        />
        <meta
          name="description"
          content="Discover the Uttar Pradesh Kabaddi League (UPKL), a pioneering initiative dedicated to nurturing local Kabaddi talent and engaging audiences nationwide. Join us in celebrating and revitalizing the spirit of Kabaddi, ensuring its growth and entertainment value across India."
        />
        <link rel="canonical" href="https://upkl.in" />
      </Helmet>
      <Nav />
      <Banner />
      <Fixture />
      <StandingsTable />
      <section className="videoplayer">
       <div className="container-fluid">
       <div className="row">
          <div className="col-md-1 col-12"></div>
          <div className="col-12  col-md-10">
          <div className="titlecaption mx-auto">
          <div className="d-flex">
            <div className="newarrows">
              <h2 className="text-white text-center">UPKL KABADDI PROMO</h2>
              <div className="linetilte mb-2"></div>
            </div>
            <div className="abc">
              <img src={arrow} className="textrightarrow" alt="UPKL news" />
            </div>
          </div>
        </div>


        <video muted autoplay loop controls>
  <source src={video} type="video/mp4"/>
  Your browser does not support the video tag.
</video>

<Link to="https://www.youtube.com/@UttarPradeshKabaddiLeague" target="_blank">
<img src={showimg} className="img-fluid showmorebtn"/>
</Link>
          </div>

          <div className="col-md-1 col-12"></div>

        </div>
       </div>
      </section>
      <section className="playerstatssection">
        <div className="bluenewletter">
          <div className="row">
            <div className="col-md-6 col-12">
              <img
                src={Auctionimg}
                data-toggle="modal"
                data-target="#exampleModalCenter"
                className="img-fluid"
                alt="Uttar Pradesh Kabaddi League (UPKL)
Kabaddi in India
UPKL MEGA  AUCTION 2024"
              />
            </div>
            <div className="col-md-6 col-12 align-self-center">
              <div className="newletterright">
                <h5>UPKL Mega Auction 2024: Fighting for the Champs</h5>
                <div className="line"></div>
                <p>
                  Witness the mad fight for selecting the acer players at the
                  UPKL 2024 Auction went down on the 10th of June in Noida.
                  Binge it Live!
                </p>

                <div className="row">
                  <div className="newlogos">
                    <img
                      src={team}
                      className="img-fluid"
                      alt="Uttar Pradesh Kabaddi League (UPKL)
Kabaddi in India
UPKL MEGA  AUCTION 2024"
                    />
                    <div className="date">17 June, 2024</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <Tv/> */}
      </section>
      <section className="kabaddiman" id="rahul">
        <div className="overlay-content">
          <div className="row row1">
            <div className="col-md-7 col-12"></div>
            <div className="col-md-5 col-12">
              <div className="newletterright">
                <h5>
                  Rahul Chaudhary: advocating for better sports infrastructure{" "}
                </h5>
                <div className="line"></div>
                <p>
                  Rahul Chaudhry, a celebrated of the Uttar Pradesh Kabaddi
                  League (UPKL) 2024, exemplifies dedication and passion in
                  promoting kabaddi across the region. His efforts in inspiring
                  young athletes and advocating for better sports infrastructure
                  have significantly contributed to the sport's development and
                  recognition worldwide .
                </p>
              </div>
              <img
                src={borderimg}
                className="img-fluid borderimg"
                alt="Uttar Pradesh Kabaddi League (UPKL) Kabaddi in India UPKL MEGA  AUCTION 2024"
              />
            </div>
          </div>
        </div>
      </section>
      <section>
        <Link to="https://maps.app.goo.gl/mfkwJ4hGXT5rqpYy6" target="_blank">
          <img
            src="https://dev-upkl.upkl.in/files/Frame 214 (2).svg"
            className="img-fluid w-100"
            alt="Uttar Pradesh Kabaddi League (UPKL) Kabaddi in India UPKL MEGA  AUCTION 2024"
          />
        </Link>
      </section>
      <GalleryPlayer/>
      <New />
      <Footer />
      <div
        className="modal fade"
        id="exampleModalCenter"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-lg modal-dialog-centered"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-body">
              <iframe
                width="100%"
                src="https://www.youtube.com/embed/YjCJy0K42tA?si=CdSQi72mcgidfv1u"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerpolicy="strict-origin-when-cross-origin"
                allowfullscreen
              ></iframe>{" "}
            </div>
          </div>
        </div>
      </div>

     {/* <div className="modal" id="myModal" tabIndex="-1" role="dialog" style={{ display: showModal ? 'block' : 'none' }}>
  <div class="modal-dialog modal-lg modal-dialog-centered popupnew"  ref={modalRef}>
    <div class="modal-content">
      <div class="modal-body p-0">
        <img src={modal}  className="img-fluid popmodal"/>
      </div>
    
    </div>
  </div>
</div> */}



    </>
  );
};

export default Home;
