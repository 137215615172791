import React, { useState, useEffect } from "react";
import Nav from "../Include/Nav";
import arrow from "../arrow.svg";
import { Helmet } from "react-helmet";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import Footer from "../Include/Footer1";
const breadcumb = "https://dev-upkl.upkl.in/files/breadcumb1.png";

export default function New() {
  const [standings, setStandings] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchStandings = async () => {
      try {
        const response = await fetch(
          'https://dev-upkl.upkl.in/api/resource/Print%20Coverage?fields=["*"]'
        );
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const data = await response.json();
        console.log("Fetched data:", data); // Log the fetched data
        setStandings(data.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchStandings();
  }, []);

  const options3 = {
    loop: true,
    margin: 20,
    nav: false,
    dots: false,
    autoplay: true,
    autoplayTimeout: 4200,
    responsive: {
      0: {
        items: 1.6,
      },
      600: {
        items: 3,
      },
      1000: {
        items: 3.9,
      },
    },
  };

  return (
    <>
      <Helmet>
        <title>Latest UPKL News | UPKL Updates & Announcements</title>
        <meta
          name="title"
          content="Latest UPKL News | UPKL Updates & Announcements"
        />
        <meta name="keywords" content="Latest News" />
        <meta
          name="description"
          content="Stay informed with the latest news, updates, and announcements from the UPKL. Get insights on match highlights, team news, and player updates in the UPKL."
        />
        <link rel="canonical" href="https://upkl.in/news" />
      </Helmet>
      <Nav />
      <section className="breadcrumb">
        <img src={breadcumb} className="img-fluid" alt="UPKL news" />
        <div className="breadcrumb-caption">
          <div className="d-flex">
            <div className="newarrows">
              <h2 className="text-white">NEWS AND MEDIA</h2>
              <div className="linetilte"></div>
            </div>
            <div className="abc">
              <img src={arrow} className="textrightarrow" alt="UPKL news" />
            </div>
          </div>
        </div>
      </section>

      <section className="newpage">
        <div className="titlecaption">
          <div className="d-flex">
            <div className="newarrows">
              <h2 className="text-white">PRINT COVERAGE              </h2>
              <div className="linetilte mb-2"></div>
            </div>
            <div className="abc">
              <img src={arrow} className="textrightarrow" alt="UPKL news" />
            </div>
          </div>
        </div>
        {loading ? (
          <SkeletonTheme baseColor="#292679" highlightColor="#39358b70">
            <Skeleton width={260} height={400} />{" "}
          </SkeletonTheme>
        ) : (
          <OwlCarousel className="owl-theme" {...options3}>
            {standings.length > 0 ? (
              standings.map((team, index) => (
                <div className="card" key={index}>
                  <img
                    src="/static/media/borderblog.21867030a71636afa32cfd31be23043a.svg"
                    className="img-fluid bordernew"
                    alt="UPKL news"
                  />
                  <img
                    className="card-img-top"
                    src={`https://dev-upkl.upkl.in/${team.image}`}
                    alt="UPKL news"
                  />
                </div>
              ))
            ) : (
              <p>No data available</p>
            )}
          </OwlCarousel>
        )}
      </section>
      <Footer />
    </>
  );
}
