// s.js

import React from "react";
import { useParams } from "react-router-dom"; // Import useParams from react-router-dom
import Footer from "./Include/Footer1";
import Nav from "./Include/Nav";
import arrow1 from "./arrow.svg";
import { useLocation } from 'react-router-dom';

import { Helmet } from "react-helmet";

function Stats() {
  const breadcumb = "https://dev-upkl.upkl.in/files/breadcumb1.png";

  // Use useParams hook to get matchid from URL
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const matchid = queryParams.get('matchid');

  return (
    <>
      <Nav />


      <section className="breadcrumb">
        <img src={breadcumb} className="img-fluid" alt="UPKL TEAM" />
        <div className="breadcrumb-caption">
          <div className="d-flex">
            <div className="newarrows">
              <h2 className="text-white">UPKL STATS</h2>
              <div className="linetilte"></div>
            </div>
            <div className="abc">
              <img
                src={arrow1}
                className="textrightarrow"
                alt="UP Kabaddi League teams
UPKL team details
uttar pradesh kabaddi teams"
              />
            </div>{" "}
          </div>
        </div>
      </section>


      <section>
        {/* Dynamically set the src attribute of the iframe */}
        <iframe
          className="iframenew"
          src={`https://dz8zbd8amw7pd.cloudfront.net/UPKL/widgets/LeaderBoard/index.html?c=66`}
          width="100%"
          height="100%"
          frameBorder="0"
          scrolling="auto"
          title="Kabaddi Match Center"
          style={{ overflow: "hidden" }}
        />
      </section>
      <Footer />
    </>
  );
}

export default Stats;
