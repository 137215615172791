import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import l1 from  './Kanpur/kanpur 01.jpg'
import l2 from  './Kanpur/kanpur 02.jpg'
import l3 from  './Kanpur/kanpur 03.jpg'
import l4 from  './Kanpur/kanpur 04.jpg'
import arrow from "../arrow.svg";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import arrow1 from "../arrow.svg";
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Footer from '../Include/Footer1';
import Nav from '../Include/Nav';
import Banner from '../Components/Banner';
const breadcumb = "https://dev-upkl.upkl.in/files/breadcumb1.png";

const Mathura = () => {
  const [activeCity, setActiveCity] = useState('mathura');


  const options = {
    loop: true,
    margin:10,
    nav: false,
    // autoplay: true, // Enable autoplay
    // autoplayTimeout: 3000, // Autoplay interval in milliseconds (3 seconds)
    responsive: {
        0: {
            items: 1.4,
            margin: 5,
        },
        600: {
            items: 3
        },
        1000: {
            items: 4
        }
    }
};


  const renderContent = () => {
    switch (activeCity) {
      case 'mathura':
        return (
          <>
            <div className="col-md-4 col-12">
              <div className="trophy-content">
                <h5>Mathura's Brij Stars Shine Bright During the UPKL Trophy Tour
                </h5>
                <p>
                In Mathura, the birthplace of Lord Krishna, the Brij Stars welcomed the UPKL Trophy with traditional festivities and cultural performances. </p><p>
The city's spiritual ambiance and love for sports came together in a vibrant celebration that captivated both locals and visitors. The event featured traditional dances, music, and a grand procession through the city. 


                </p>
              </div>
            </div>
            <div className="col-4"></div>
            <div className="col-md-4 col-12">
              <div className="trophy-content2">
                <h5>Godhuli of Vrindavan</h5>
                <p>
                Mathura's deep-rooted cultural heritage and passion for kabaddi were on full display, making this stop on the tour a highlight for fans and participants alike.</p><p>
“Radhe Radhe karte hue haste hue jeet jaenge” said one local in our candid talk vox pops for Brij Stars Team</p>
              </div>
            </div>
          </>
        );

      default:
        return null;
    }
  };

  return (
    <>
<Nav/>

<section className="breadcrumb">
        <img src={breadcumb} className="img-fluid" alt="Breadcrumb Image" />
        <div className="breadcrumb-caption">
          <div className="d-flex">
            <div className="newarrows">
              <h2 className="text-white">TROPHY  TOUR</h2>
              <div className="linetilte"></div>
            </div>
            <div className="abc">
              <img src={arrow1} className="textrightarrow" alt="Arrow" />
            </div>
          </div>
        </div>
      </section>

      <section className="stripcity">
        <div className="trophy-city">
          <ul className="d-flex">
             <li className={activeCity === 'lucknow' ? 'touractive' : ''}><Link to="/lucknow" onClick={() => setActiveCity('lucknow')}>Lucknow</Link></li>
            <li className={activeCity === 'ayodhya' ? 'touractive' : ''}><Link to="/ayodhya-tour" onClick={() => setActiveCity('ayodhya')}>AYODHYA</Link></li>
            <li className={activeCity === 'kashi' ? 'touractive' : ''}><Link to="/kashi-tour" onClick={() => setActiveCity('kashi')}>Kashi</Link></li>
            <li className={activeCity === 'mirzapur' ? 'touractive' : ''}><Link to="/mirzapur-tour" onClick={() => setActiveCity('mirzapur')}>MIRZAPUR</Link></li>
            <li className={activeCity === 'prayagraj' ? 'touractive' : ''}><Link to="/prayagraj-tour" onClick={() => setActiveCity('prayagraj')}>PRAYAGRAJ</Link></li>
            <li className={activeCity === 'kanpur' ? 'touractive' : ''}><Link to="/kanpur-tour" onClick={() => setActiveCity('kanpur')}>KANPUR</Link></li>
            <li className={activeCity === 'jashi' ? 'touractive' : ''}><Link to="/jashi-tour" onClick={() => setActiveCity('jashi')}>Jhansi
</Link></li>
            <li className={activeCity === 'mathura' ? 'touractive' : ''}><Link to="/mathura-tour" onClick={() => setActiveCity('noida')}>Mathura</Link></li>

            <li className={activeCity === 'agra' ? 'touractive' : ''}><Link to="/agra-tour" onClick={() => setActiveCity('noida')}>Agra</Link></li>
            <li className={activeCity === 'noida' ? 'touractive' : ''}><Link to="/noida-tour" onClick={() => setActiveCity('noida')}>NOIDA</Link></li>
          </ul>
        </div>
      </section>
      <section className="trophysection">
        <div className="trophysection1">
          <div className="container-fluid">
            <div className="row">
              {renderContent()}
            </div>
          </div>
        </div>
      </section>

      <section className='fixturessection1'>
      <div className='titlecaption'>
                    <div className='d-flex'>
                        <div className='newarrows'>
                            <h2 className='text-white'>MATHURA GALLERY</h2>
                            <div className='linetilte'></div>
                        </div>
                        <div className='abc'>
                            <img src={arrow} className='textrightarrow' alt="arrow" />
                        </div>
                    </div>
                </div>

        <div className='container-fluid'>
          
        <OwlCarousel className="owl-theme" {...options}>
          <div className='itemluck'>
            <img src={l1} className='img-fluid'/>
          </div>

          <div className='itemluck'>
            <img src={l2} className='img-fluid'/>
          </div>


          <div className='itemluck'>
            <img src={l3} className='img-fluid'/>
          </div>


          <div className='itemluck'>
            <img src={l4} className='img-fluid'/>
          </div>


        
          </OwlCarousel>
          
        </div>
      </section>

      <Footer/>
    </>
  );
};

export default Mathura;
