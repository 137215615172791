import React, { useState, useEffect } from "react";
import Nav from "../Include/Nav";
import Footer from "../Include/Footer1";
import arrow from "../arrow.svg";
import arrow1 from "../arrow.svg";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

export default function Players() {
  const [players, setPlayers] = useState([]);
  const [team, setTeams] = useState([]);

  const [breadcumb, setBreadcumb] = useState("https://dev-upkl.upkl.in/files/breadcumb1.png");
const [id , setId] = useState('')
  useEffect(() => {
    fetchPlayers();
    fetchTeam();

  }, []);
  

  const fetchPlayers = async () => {
    try {

      const params = new URLSearchParams(window.location.search);
      const id = params.get("id");

      setId(id);
      const response = await fetch(
        `https://dev-upkl.upkl.in/api/resource/UPKL%20Players?filters=[["team",%20"=",%20"${id}"]]&fields=[%22*%22] &order_by=playername asc`

      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      setPlayers(data.data); // Assuming the API response has a structure where data is an array of player objects
    } catch (error) {
      console.error("Error fetching players:", error);
    }
  };

  const fetchTeam = async () => {
    try {

      const params = new URLSearchParams(window.location.search);
      const id = params.get("id");

      setId(id);
      const response = await fetch(
        `https://dev-upkl.upkl.in/api/resource/UPKL%20Teams/${id}`

      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      setTeams(data.data); // Assuming the API response has a structure where data is an array of player objects
    } catch (error) {
      console.error("Error fetching players:", error);
    }
  };

  return (
    <>
      <Helmet>
        <title>Uttar Pradesh Kabaddi League (UPKL) - Empowering Kabaddi Athletes.</title>
        <meta name="title" content="Uttar Pradesh Kabaddi League (UPKL) - Empowering Kabaddi Athletes." />
        <meta
          name="description"
          content="Learn about the Uttar Pradesh Kabaddi League (UPKL), a dedicated platform committed to nurturing local Kabaddi talents. Understand UPKL's mission to engage audiences and reinforce Kabaddi's significance in India."
        />
        <link rel="canonical" href="http://upkl.in/about" />
      </Helmet>

      <Nav />


      <section  className="teamsdeatilsection">

<div className="container-fluid">
  <div className="teamdetailbox">
    <div className="row">
      <div className="col-md-7 col-12 align-self-center">
        <div className="row">
          <div className="col-4 align-self-center">
          <div className="teamname-player">
          <img src={team.image ? `https://dev-upkl.upkl.in/${team.image}` : 'https://dev-upkl.upkl.in/files/VECTOR-WEBSITE.png-03.'} alt="Player Image" className="imageteamlogo" />
          </div>
        <Link to={team.website} target="_blank">
        <button className="">Official Team Site</button></Link>

          </div>

          <div className="col-md-6 col-12 align-self-center">
          <div className="teamname-player">
          <div className="titlecaption">
          <div className="d-flex">
        

            <div className="newarrows">
              <h2 className="text-white">{team.teamname}</h2>
              <div className="linetilte mb-2"></div>
            </div>
            <div className="abc">
              <img src={arrow} className="textrightarrow" alt="UPKL news" />
            </div>
          </div>
        </div>
         
        </div>
  <h5>Total Points</h5>
        <div className="d-flex">
          
        <div class="box">
        <h2>{team.totalpoints}</h2>
        <p>Total Points</p>
    </div>

    <div class="box">
        <h2>{team.totalraidpoints}</h2>
        <p>Total Raid Points
        </p>
    </div>


    <div class="box">
        <h2>{team.totaltacklepoints}</h2>
        <p>Total Tackle Points
        </p>
    </div>


   
        </div>


          </div>
        </div>
      </div>
      <div className="col-md-5 align-self-center">
   <div className="row">
    <div className="col-md-6">
      <div className="headinpointer">
        <h6>Raid Points
        </h6>
      </div>
      <div className="verticalbg">
        <ul>
          <li><div className="row">
            <div className="col-10"><p className="playerstaticpage">Touch Points</p>
            </div>
            <div className="col-2"><p className="playerstaticpage">{team.touchpoints}</p></div>
            </div></li>
            <li><div className="row">
            <div className="col-10"><p className="playerstaticpage">Raid Bonus Points</p>
            </div>
            <div className="col-2"><p className="playerstaticpage">{team.raidbonuspoints}</p></div>
            </div></li>
        </ul>
      </div>
    </div>
    <div className="col-md-6">
    <div className="headinpointer">
        <h6>Tackle Points

        </h6>
      </div>
      <div className="verticalbg">
        <ul>
          <li><div className="row">
            <div className="col-10"><p className="playerstaticpage">Capture Points
            </p>
            </div>
            <div className="col-2"><p className="playerstaticpage">{team.capturepoints}</p></div>
            </div></li>
            <li><div className="row">
            <div className="col-10"><p className="playerstaticpage">Tackle Bonus Points</p>
            </div>
            <div className="col-2"><p className="playerstaticpage">{team.tacklebonuspoints}</p></div>
            </div></li>
        </ul>
      </div>
    </div>

   </div>
      </div>

    </div>
  </div>
<div>

</div></div>
      </section>

      <section className="aboutsection">
        <div className="container-fluid">
          <div className="titlecaption">
            <div className="d-flex">
              <div className="newarrows">
                <h2 className="text-white">Players</h2>
                <div className="linetilte"></div>
              </div>
              <div className="abc">
                <img src={arrow} className="textrightarrow" alt="Arrow" />
              </div>{" "}
            </div>
          </div>

          <div className="row">
            {players.map((player) => (
              <div className="col-md-3" key={player.name}>
                <div className="playerscards">
                  <div className="playerbox">
                    <div className="jerseynumber">
                      <p>{player.jerseyno}</p>
                    </div>
                    <img src={player.compressed_img ? `https://dev-upkl.upkl.in/${player.compressed_img}` : 'https://dev-upkl.upkl.in/files/VECTOR-WEBSITE.png-03.png'} alt="Player Image" />


                  </div>
                  <div className="playerfooter">
                    <div className="row">
                      <div className="col-8">
                        <div className="playerfooter1">
                          <h5>{player.playername}</h5>
                          <p> {player.dob}</p>
                        </div>
                      </div>
                      <div className="col-4 align-self-center">
                        <div className="View Details">
                         <Link to={`/playerdetail?id=${player.name}`}>
                         <button className="viewstats">View Stats</button></Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}
