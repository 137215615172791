import React ,{useEffect} from "react";
import { Link, useLocation } from "react-router-dom";
import arrow from "./arrowpreheader.svg";
export default function Nav() {
  const { pathname } = useLocation();

  useEffect(() => {
    // Scroll to the top of the page whenever the route changes
    window.scrollTo(0, 0);
  }, [pathname]);


  const logo = "https://dev-upkl.upkl.in/files/Frame 365.png";
  const location = useLocation();
  return (
    <>
      <div className="preheader mobilenone">
        <div className="row">
          <div className="col-5 align-self-center">
            <div className="preheadingtext">
              <p>
              Explore player statistics now available on the website.

                <img src={arrow} className="img-fluid float-right" alt="Uttar Pradesh Kabaddi League (UPKL)
Kabaddi in India
UPKL MEGA  AUCTION 2024" />
              </p>
            </div>
          </div>
          <div className="col-5 align-self-center">
            <div className="preheadingtext">
              <p>
              Lucknow Lions leading UPKL with a Stellar Performance!
                <img src={arrow} className="img-fluid float-right" alt="Uttar Pradesh Kabaddi League (UPKL)
Kabaddi in India
UPKL MEGA  AUCTION 2024" />
              </p>
            </div>
          </div>
        </div>
      </div>
      <nav className="navbar navbar-expand-lg navbar-light bg-light mobilenone">
        <div className="container-fluid">
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav mr-auto">
              <li className="nav-item">
                <Link
                  className={
                    location.pathname === "/" ? "active nav-link" : "nav-link"
                  }
                  to="/"
                >
                  HOME
                </Link>
              </li>

              <li className="nav-item">
                <Link
                  className={
                    location.pathname === "/matches"
                      ? "active nav-link"
                      : "nav-link"
                  }
                  to="/matches"
                >
                  MATCHES
                </Link>
              </li>

              <li className="nav-item">
                <Link 
                className={
                  location.pathname === "/stats"
                    ? "active nav-link"
                    : "nav-link"
                }
                 to="/stats">
                  {" "}
                 STATS
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className={
                    location.pathname === "/auction"
                      ? "active nav-link"
                      : "nav-link"
                  }
                  to="/auction"
                >
                  AUCTION
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className={
                    location.pathname === "/standing"
                      ? "active nav-link"
                      : "nav-link"
                  }
                  to="/standing"
                >
                  STANDINGS
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className={
                    location.pathname === "/team"
                      ? "active nav-link"
                      : "nav-link"
                  }
                  to="/team"
                >
                  TEAMS
                </Link>
              </li>

              
            </ul>
            <Link className="navbar-brand mx-auto" to="/">
              <img
                src={logo}
                alt="Uttar Pradesh Kabaddi League (UPKL)
Kabaddi in India
UPKL MEGA  AUCTION 2024"
                className="img-fluid upkllogo mobilenone"
              />
            </Link>
            <ul className="navbar-nav ml-auto">
              <li className="nav-item">
                <Link className="nav-link" to="/news">
                  NEWS
                </Link>
              </li>


              <li className="nav-item">
                <Link className="nav-link" to="/sponsor">
                  SPONSORS
                </Link>
              </li>



              <li className="nav-item">
                <Link
                  className={
                    location.pathname === "/blog-list"
                      ? "active nav-link"
                      : "nav-link"
                  }
                  to="/blog-list"
                >
                  {" "}
                  BLOG
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className={
                    location.pathname === "/about"
                      ? "active nav-link"
                      : "nav-link"
                  }
                  to="/about"
                >
                  {" "}
                  ABOUT
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/upkltv">
                  {" "}
                  UPKL TV
                </Link>
              </li>

             
              <li className="nav-item">
                <Link
                  className={
                    location.pathname === "/lucknow"
                      ? "active nav-link"
                      : "nav-link"
                  }
                  to="/lucknow"
                >
                  {" "}
                  TROPHY TOUR
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>

      <nav className="navigation desktopnone navbar" role=" ">
        <div id="menuToggle">
          <input type="checkbox" />
          <span></span>
          <span></span>
          <span></span>
          <ul id="menu">
            <li
              className={`nav-item ${
                location.pathname === "/" ? "active" : ""
              }`}
            >
              <Link className="nav-link" href="/">
                HOME
              </Link>
            </li>
            <li
              className={`nav-item ${
                location.pathname === "/auction" ? "active" : ""
              }`}
            >
              <Link className="nav-link" to="/auction">
                AUCTION
              </Link>
            </li>
            <li
              className={`nav-item ${
                location.pathname === "/standing" ? "active" : ""
              }`}
            >
              <Link className="nav-link" to="/standing">
                STANDINGS
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/team">
                TEAMS
              </Link>
            </li>

            <li className="nav-item">
              <Link className="nav-link" to="/matches">
                Matches
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/blog-list">
                {" "}
                BLOG
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/news">
                {" "}
                NEWS
              </Link>
            </li>

            <li className="nav-item">
              <Link className="nav-link" to="/stats">
                {" "}
                STATS
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/about">
                ABOUT
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/sponsor">
                {" "}
                SPONSORS
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/?id=rahul">
                {" "}
                <Link className="nav-link" to="/lucknow">
                  {" "}
                  TROPHY TOUR
                </Link>
              </Link>
            </li>
          </ul>
        </div>
        <div className="mblogo">
          <Link to="/">
            {" "}
            <img src={logo}  className="img-fluid" alt="Uttar Pradesh Kabaddi League (UPKL)
Kabaddi in India
UPKL MEGA  AUCTION 2024"/>
          </Link>{" "}
        </div>
      </nav>
    </>
  );
}
