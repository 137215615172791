import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import Nav from "../Include/Nav";
import Footer from "../Include/Footer1";
import border from "../borderblog.svg";
import arrow1 from "../arrow.svg";
import arrownlblog from "../arrownlblog.svg";
// import ImageZoom from "react-medium-image-zoom";

export default function Photodetails() {
  const breadcumb = "https://dev-upkl.upkl.in//files/breadcumb1.png";
  const [galleryItem, setGalleryItem] = useState(null);
  const [loading, setLoading] = useState(true);
  const [zoomIndex, setZoomIndex] = useState(null); // To track which image is zoomed

  useEffect(() => {
    const fetchGalleryItem = async () => {
      try {
        const params = new URLSearchParams(window.location.search);
        const id = params.get("id");
        const response = await fetch(
          `https://dev-upkl.upkl.in/api/resource/UPKL%20Gallery/${id}`
        );
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const data = await response.json();
        setGalleryItem(data.data); // Store the fetched item's "data" object in state
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false); // Always set loading to false whether success or failure
      }
    };
    fetchGalleryItem();
  }, []); // Empty dependency array means this effect runs only once, like componentDidMount

  const toggleZoom = (index) => {
    setZoomIndex(index === zoomIndex ? null : index);
  };

  return (
    <>
      <Nav />
      <Helmet>
        <title>UPKL Gallery</title>
      </Helmet>

      <section className="breadcrumb">
        <img src={breadcumb} className="img-fluid" alt="UPKL BLOG" />
        <div className="breadcrumb-caption">
          <div className="d-flex">
            <div className="newarrows">
              <h2 className="text-white">UPKL GALLERY</h2>
              <div className="linetilte"></div>
            </div>
            <div className="abc">
              <img src={arrow1} className="textrightarrow" alt="UPKL BLOG" />
            </div>
          </div>
        </div>
      </section>

      <section className="galleryupkl">
        <div className="container-fluid">
          <div className="row">
            {loading ? (
              <p>Loading...</p>
            ) : galleryItem ? (
              galleryItem.images && galleryItem.images.length > 0 ? (
                galleryItem.images.map((image, index) => (
                  <div key={index} className="col-md-4 col-12">
                    <div className="card position-relative">
                    <div className="overlay">
                    </div>
                      <img
                        id={`image-${index}`}
                        src={`https://dev-upkl.upkl.in/${image.image}`}
                        className="card-img-top img-fluid photodetails"
                        alt={image.alt}
                        onClick={() => toggleZoom(index)}
                      />
                      {zoomIndex === index && (
                        <div className="overlay">
                        </div>
                      )}
                      <img src={border} className="img-fluid bordernew" alt="" />
                    </div>
                  </div>
                ))
              ) : (
                <p>No images found.</p>
              )
            ) : (
              <p>No data found.</p>
            )}
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}
